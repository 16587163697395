import styled from "@emotion/styled";
import { Container, Grid, Typography } from "@mui/material";

const SectionWraper = styled(Container)`
  padding: 0 !important;
  width: 100%;
  height: 100vh;
  z-index: 0;
  position: relative;
  background-image: url("./Image/heroBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media(max-width: 599px) {
    /* height: 1 !important; */
  }
`;
const GradiantDiv = styled.div`
  padding: 0 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left:0;
  z-index: 1;
  background-image: radial-gradient(circle at 110% 50%, #0000, #091C23 60%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
const ContainerWraper = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
`;
const EmptyGrid = styled(Grid)`
  width: 100%;
  height: 80px;
  @media (max-width: 599px) {
    display: none;
  }
`;
const Paragraph = styled(Typography)`
  color: #fff;
  font-size: 14px;
  font-family: PoppinsR;
  margin-top: 20px;
  z-index: 1;
`;
const Image = styled.img`
  width: 100%;
  max-width: 280px;
  height: auto;
  z-index: 1;
  @media (max-width: 599px) {
    max-width: 200px;
  }
`;
const ImageGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 599px) {
    justify-content: center;
  }
`;
const ParentGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  
  z-index: 1;
  @media (max-width: 599px) {
    flex-direction: column-reverse;
    flex-wrap: nowrap;
  }
`;
const ContentGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* margin: 50px 0 0; */
  @media (max-width: 599px) {
    margin: 10px 0 0;
  }
`;
const BtnDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  margin: 20px 0 0;
  /* @media(max-width :599px){
        margin: 20px 0 0;
    } */
`;
const FooterDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  @media(max-width :599px){
        flex-direction: column;
    }
`;

const SocialDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: PoppinsR;
  margin: 10px 0 0;
  z-index: 1;
  /* @media(max-width :599px){
        flex-direction: column;
    } */
`;

export {
  SectionWraper,
  Paragraph,
  EmptyGrid,
  Image,
  ImageGrid,
  ParentGrid,
  ContentGrid,
  BtnDiv,
  FooterDiv,
  SocialDiv,
  GradiantDiv,
  ContainerWraper,
};
