import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from "react-redux";
import Swap from '../Views/Pages/Swap';
import Home from "../Views/Pages/P1-Home";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Tokenomics from "../Views/Pages/P2-Tokenomics";
import RoadMap from "../Views/Pages/P3-RoadMap";
const getActiveTheme = (themeMode) => {
  return createTheme({
    pageColor: '#000',

    bgPrimary: '#ffffff263d',
    bgSecondary: 'red',

    textPrimary: '#ffffff',
    textSecondary: 'black',
    textTertiary: '#0E0D0D',
    BtnHover: '#311f1f7b',

    switchColor: '#b1b1b1',
  })
}
const ThemeContainer = () => {
  const currentTheme = useSelector((state) => state.LDTheme.value);
  const [activeTheme, setActiveTheme] = useState(getActiveTheme(currentTheme));

  useEffect(()=>{    
    setActiveTheme(getActiveTheme(currentTheme))
  },[currentTheme]);
  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Swap />} />
          <Route exact path="/liquidity" element={<Swap />} />
          </Routes>
      </BrowserRouter>
      {/* <MainPage toggleTheme='' /> */}
    </ThemeProvider>
  );
}

export default ThemeContainer;