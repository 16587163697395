import React, { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useNetwork, useSwitchNetwork } from "wagmi"
import styled from '@emotion/styled';

const SwitchBtn = styled(Button)`
color: ${(props) => props.theme.btnPrimary};
outline: unset !important;
text-transform: capitalize;
font-weight: 600;
height: unset;
`
const ButtonGroupStyled = styled(ButtonGroup)`
background-color: ${(props) => props.theme.btnPrimary};
    border-radius: 20px;
    box-shadow: unset;
    svg{fill: #FFF;}
    button{
        background-color: unset !important;
        color: ${(props) => props.theme.textPrimary};
        border-right: 0px !important;
        border-radius: 30px;
        padding: 0px;
        &:nth-of-type(1){padding: 8px 0 8px 22px}
        &:nth-of-type(2){padding: 8px 8px 8px 0}
    }
    &:hover{
    
background-color: ${(props) => props.theme.btnPrimary};
}
    `

const ChainList = styled(MenuList)`
background-color: ${(props) => props.theme.bgTertiary};
color: ${(props) => props.theme.textPrimary};
    margin-top: 3px;
    border-radius: 10px;
`
const NetworkIcon = styled.img`
    height: 30px;
    width: auto;
    padding-right: 7px;
`

export default function NetworkSwitch() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const { chains } = useNetwork()
  const { switchNetwork } = useSwitchNetwork()

  const handleMenuItemClick = (event, index) => {
    switchNetwork(index);
    setOpen(false);
  };

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroupStyled variant="contained" ref={anchorRef} aria-label="Switch Netwrok">
        <SwitchBtn onClick={handleToggle} className="right">Switch Network</SwitchBtn>
        <SwitchBtn
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          className='left'
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </SwitchBtn>
      </ButtonGroupStyled>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
              borderRadius: 11
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <ChainList id="split-button-menu" autoFocusItem>
                  {chains.map((option, index) => {
                    return (
                      <MenuItem
                        key={option}
                        onClick={(event) => handleMenuItemClick(event, option.id)}
                      >
                        <NetworkIcon src={option.icon} />{option.name}
                      </MenuItem>
                    )
                  }
                  )}
                </ChainList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}