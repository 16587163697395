import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  align-items: center;
  @media screen and (max-width: 672px) {
    display: block;
  }
`;
const Logo = styled.a``;
const NetworkWallet = styled.div`
  @media screen and (max-width: 672px) {
    margin: 30px 0;
    text-align: -WEBKIT-CENTER;
  }
`;
const Network = styled.div`
  font-size: 14px;
  border-radius: 20px;
  background: linear-gradient(to right, #ec9dc1, #f7a224, #5151e4, );
  display: flex;
  align-items: center;
  display: inline-flex;
`;
const Wallet = styled.button`
  background-color: #fff;
  color: #000;
  padding: 7px 40px;
  border-radius: 20px;
  font-size: 14px;
  @media screen and (max-width: 460px) {
    margin-top: 20px;
  }
`;
const Div = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  @media screen and (max-width: 460px) {
    display: block;
  }
`;
const Connected = styled.div`
  font-size: 14px;
  background-color: #242424;
  border-radius: 20px;
  padding: 7px 40px;
  display: inline-block;
`;


const MenuContainer = styled.div`
  display: flex;
  padding: 0;
justify-content: flex-end;
    width: 100%;
  align-items: center;
  
`
const MenuLink = styled.a`
  padding: 5px 10px;
  color: #ffffff;
  
`
const DropDown = styled.div`
position: relative;
  display: inline-block;
  ul{
    position: absolute;
    list-style: none;
    background: white;
    padding: 0;
    left: 0;
    right: 0;
    top: 13px;
    border-radius: 5px;
    box-shadow: 0px 0px 3px black;
    display: none;
    a{
      color: #000000;
      padding: 5px 10px;
      display: inline-block
    }
  }
  &:hover{
    color: #f4f4f4;
    text-decoration: none;

    ul{
      display:block;
      &:hover{
        color: #181818 !important;
      }
    }
  }
`
const MenuDiv = styled.div`
  border: 1px solid #b9825d;
  border-radius: 20px;
  padding: 6px 20px;
  @media(max-width: 856px){
      display: none;
  }
`

export { MenuContainer, MenuLink, DropDown, MenuDiv, Wrapper, Logo, NetworkWallet, Network, Wallet, Div, Connected };
