import * as React from "react";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import { Split } from "../../Pages/Swap/S1_Swap/style";
import { Grid, Input,  Typography } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';


const TriggerButton = styled(MenuButton)`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const MenuDiv = styled(Menu)`
  background-color: #161616;
  border: none;
`;
const ParentGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
`;
const ChildGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 5px 10px;
  cursor: pointer;
`;

const InputField = styled(Input)`
  width: ${props => props.w ? props.w : "100%"};
  
  height: 25px;
  border: none;
  font-size: 12px;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  & > input {
    text-align: right;
    position: relative;
    ::after {
    content: '%';
    position: absolute;
    right: 0;
    top: 50%;
    color: #555;
  }
  }
  :focus {
    outline: none;
    border-bottom: none;
  }
  ::before {
    content: none;
    border-bottom: none;
  }
  ::after {
    content: none;
    border-bottom: none;
  }
`;
export default function SettingDropDown(props) {
  // slipage={useInputSlipage} deadline={useInputDeadline} funcDeadline={handleDeadline} funcSlipage={handleSlipage}
  const [selectedslipage, setSelectedslipage] = React.useState(props.slipage);
  const [selectedTime, setSelectedTime] = React.useState(props.deadline);

  const handleAutoClick = () => {
    setSelectedslipage("0.1")
    props.funcSlipage("0.1");
    setSelectedTime("15")
    props.funcDeadline("15");
};

  const handleSlipageChange = (value) => {
      setSelectedslipage(value)
      props.funcSlipage(value);
    
  };
  const handleTimeChange = (value) => {
      setSelectedTime(value)
      props.funcDeadline(value);
  };

  return (
    <Dropdown>
      <TriggerButton>
        <SettingsIcon/>
      </TriggerButton>
      <MenuDiv slots={{ listbox: StyledListbox }}>
        <Split>
          <div style={{ display: "flex" }}>
            <SettingsIcon sx={{ fontSize: "20px" }} />
            <Typography sx={{ fontSize: "16px" }}>Setting</Typography>
          </div>
        </Split>
        <Split>
          <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
            Slippage Tolerance
          </Typography>
        </Split>
        <Split>
          <ParentGrid>
        <ChildGrid
            item
            xs={2}
            style={{
              backgroundColor:  "#6FBA3F" ,
            }}
            onClick={() => handleAutoClick()}
          >
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              Auto
            </Typography>
          </ChildGrid>
          <InputField
          sx={{pr:"20px"}}
              value={selectedslipage}
              type="number"
              onChange={(event) => {
                handleSlipageChange(event.target.value);
              }}
            />
            <span style={{position:"absolute", right:"7%",color:"#000"}}>%</span>
            </ParentGrid>
          </Split>
            <Split>
              <Typography sx={{ fontSize: "12px" }}>
              Transaction Deadline
              </Typography>
            </Split>
            <div style={{display:"flex",alignItems:"center",columnGap:"10px"}}>
            <InputField
            value={selectedTime}
              w="30%"
              type="number"
              onChange={(event) => {
                handleTimeChange(event.target.value);
              }}
            />
            <Typography sx={{ fontSize: "12px" }}>
            Minutes
              </Typography>
              </div>
      </MenuDiv>
    </Dropdown>
  );
}

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 12px;
  margin: 12px 0;
  max-width: 300px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  
  background: ${theme.palette.mode === "dark" ? grey[900] : "##ffffff26"};
//   border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : "#fff"};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : "#272626"
  };
  z-index: 1;
  `
);
