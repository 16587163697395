import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useDisconnect, useAccount } from "wagmi"
import { useWeb3Modal } from "@web3modal/react"
import styled from '@emotion/styled';
import NetworkSwitch from './NetworkSwitch';

const ConnectBTN = styled.div`
    background-color: ${(props) => props.theme.btnPrimary};
    color: ${(props) => props.theme.textPrimary};
    display: flex;
    width: max-content;
    border-radius: 10px;
    font-family: "Poppins-Regular";
    button{
        color: ${(props) => props.theme.textPrimary};
        background-color: unset;
        border-radius: 10px;
        border: unset;
        outline: unset;
        font-size: 16px;
        font-weight: 700;
        padding: 10px;
        margin: auto;
        width: 154px;
        /* height: 48px; */

        &.add{border-radius: 10px 0 0 10px; padding: 8px 10px 8px 20px; width: unset;}
        &.dd{border-radius: 0 10px 10px 0; padding: 0px 15px 0px 10px; width: unset;}
        
        &:hover, &:active, &:focus {
            background: #00000021;
        }
        svg {
            fill: ${(props) => props.theme.textPrimary} !important; 
        }
    }
    &:hover, &:active, &:focus {
        background-color: ${(props) => props.theme.btnPrimaryHover};
    }
    
`
const MenuConnetButton = styled(ConnectBTN)`
    width: 100%;
    max-width: fit-content;
    fill:${(props) => props.theme.textPrimary};
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    display: inline-block;
    padding: 0px 20px;
    border-radius: 50px;

    button{
        width: 100%;
        height: 44px;
        cursor: pointer;
        background-color: unset !important;
    }
    &:hover,&:focus{
    background-color: ${(props) => props.theme.btnPrimary};
    }
    
`
const SwitchBtn = styled(Button)`
    color: ${(props) => props.theme.textPrimary};
    outline: unset !important;
    text-transform: capitalize;
    font-weight: 600;
    height: unset;
`
const ButtonGroupStyled = styled(ButtonGroup)`
    border-radius: 20px;
    box-shadow: unset;
    svg{fill: ${(props) => props.theme.textPrimary};}
    button{
        color: ${(props) => props.theme.textPrimary};
    background-color: ${(props) => props.theme.btnPrimary};
    border-right: 0px !important;
    border-radius: 30px;
        padding: 0px;
        &:nth-of-type(1){padding: 8px 0 8px 22px}
        &:nth-of-type(2){padding: 8px 8px 8px 0}
    }
    &:hover{
      opacity: 0.9;
    } 
    `

const ChainList = styled(MenuList)`
background-color: ${(props) => props.theme.bgTertiary};
color: black;
    margin-top: 3px;
    border-radius: 10px;
`
export default function ConnectWallet() {
  const [openDD, setOpenDD] = useState(false);
  const [checkConnection, setCheckConnection] = useState(false);
  const [getAddress, setAddress] = useState('connecting');
  const anchorRef = useRef(null);
  
  const { disconnect } = useDisconnect()
  const { address, isConnecting, isDisconnected } = useAccount()
  const { isOpen, open, close } = useWeb3Modal();

  useEffect(()=>{
    setCheckConnection( isConnecting || isDisconnected? true: false );
    if(address)setAddress(`${address.slice(0,4)}...${address.substr(38-0)}`)
  }, [address,checkConnection, isConnecting, isDisconnected])


  const handleToggle = () => setOpenDD((prevOpen) => !prevOpen);

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpenDD(false);
  };

  return (
    <>
    {checkConnection?
        <MenuConnetButton>
            {isOpen && isConnecting ? <button>Connecting</button> : null}
            {isDisconnected? <button onClick={() => open()}>Connect Wallet</button> : null}
        </MenuConnetButton>
        :
        <>
            <ButtonGroupStyled variant="contained" ref={anchorRef} aria-label="Switch Netwrok">
                <SwitchBtn onClick={handleToggle} className="right">
                    {getAddress}
                </SwitchBtn>
                <SwitchBtn
                size="small"
                aria-controls={openDD ? 'split-button-menu' : undefined}
                aria-expanded={openDD ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                className='right'
                onClick={handleToggle}
                >
                <ArrowDropDownIcon />
                </SwitchBtn>
            </ButtonGroupStyled>
            <Popper
                sx={{
                zIndex: 1,
                }}
                open={openDD}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                    transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom',
                    borderRadius: 11,
                    }}
                >
                    <Paper >
                    <ClickAwayListener onClickAway={handleClose}>
                        <ChainList id="split-button-menu" autoFocusItem>
                            <MenuItem onClick={() => disconnect()}>Disconnect</MenuItem>
                        </ChainList>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
        </>
    }
    </>
  );
}