import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Exchange from "./S1_Swap/Swap";
import { PageWrapper } from "../../Styles/style";
import { InsideWrap, SwapDiv, Wrapper } from "./S1_Swap/style";
import { Container, Grid } from "@mui/material";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import { GridWraper, TabWrap } from "./style";
import Main from "./Liquidity/main";
import SingleEth from "../../../WalletConnectivity/Single-Eth"
import SingleButton from "../../Components/DashboardMenuSingleNetwork"
import Image from "../../../assets/images/milklogo.png"
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const TabsPage = () => {
  const [value, setValue] = useState(0);
  

  // unstable_HistoryRouter();

  const updateURLswap = () => {
    // Update the URL to '/new-url'
    window.history.pushState({}, '', '/');
  };
  const updateURLliquidity = () => {
    // Update the URL to '/new-url'
    window.history.pushState({}, '', '/liquidity');
  };
  useEffect(()=>{
    const URL = window.location.href;
    const page  = URL.split("/")
    const pagestate = page[page.length-1]
    if(pagestate === ""){
      setValue(0)
    }else if(pagestate === "liquidity"){
      setValue(1)
    }
  },[value])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    //    <div>
    //     </div>
    <PageWrapper>
      <Wrapper>
      <SingleEth>
<SingleButton />
        {/* <Navbar /> */}
        <Container maxWidth="xl">
          <InsideWrap>
            <Grid container justifyContent="center">
              <GridWraper item xs={12}>
                <img  style={{ zIndex:"1" }} alt="logo" src={Image} />
                {/* <Typography sx={{fontFamily:"PoopinsSB", fontSize:"24px",pt:"12px", zIndex:"1"}}>PUPPY SWAP</Typography> */}
                <Tabs
                style={{zIndex:"1"}}
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <TabWrap label="Exchange" {...a11yProps(0)} onClick={updateURLswap}/>
                  <TabWrap label="Liquidity" {...a11yProps(1)} onClick={updateURLliquidity}/>
                </Tabs>
              </GridWraper>
              <Grid item xs={12} sm={11} md={5}>
                <CustomTabPanel value={value} index={0}>
                  <SwapDiv>
                    <Exchange />
                  </SwapDiv>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <SwapDiv>
                    <Main />
                  </SwapDiv>
                </CustomTabPanel>
              </Grid>
            </Grid>
          </InsideWrap>
        {/* <Footer /> */}
        </Container>
        </SingleEth>

      </Wrapper>
    </PageWrapper>
  );
};

export default TabsPage;
