import styled from "@emotion/styled";
const SectionSubtext = styled.p`
  font-size: 23px;
  margin: 10px 0px;
  font-family: Poppins-Bold;
  text-transform: uppercase;
`;
const Text = styled.p`
  margin: 30px 0px 20px 0px;
  font-family: "OCR";
  max-width: 50%;
  text-align: center;
  padding: 0 20px;
  font-size: 16px;
  z-index: 1;
  color: #fff;
  @media screen and (max-width: 899px) {
    max-width: 70%;
  }
  @media screen and (max-width: 599px) {
    max-width: 100%;
  }
`;

const PageWrapper = styled.div`
  background-color: ${(props) => props.theme.pageColor};
  min-height: 100vh;
`;

const SectionWrapper = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "-webkit-center;")};
  text-align: ${(props) => (props.align ? props.align : "-moz-center;")};
border-top:${(props) => (props.bt ? props.bt : "")};
  @media screen and (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "30px 0")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "30px 0")};
  }
`;

const SectionMainWrapper = styled.div`
min-height: 100vh;
display: flex;
align-items: center;
  background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "center")};
border-top:${(props) => (props.bt ? props.bt : "")};
  @media screen and (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "30px 0")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "30px 0")};
    
align-items: start;
  }
`;
const P = styled.p`
  font-weight: ${(props) => (props.fw ? props.fw : "")};
  font-family: "P-R";
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  padding: ${(props) => (props.p ? props.p : "")};
  display: ${(props) => (props.display ? props.display : "")};
  justify-content: ${(props) => (props.jc ? props.jc : "")};
  @media (max-width: 600px) {
    font-size: ${(props) => (props.fs600px ? props.fs600px : "")};
    max-width: 100%;
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
`;
const CustomContainer = styled.div`
  max-width: 2600px;
`;
const Heading = styled.div`

color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
font-size: ${(props) => props.fs ? props.fs : "calc(130px + (26 - 22) * ((100vw - 146px) / (1600 - 250)))"};
  line-height:120px;
font-family: "P-B";
  width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  text-align: ${(props) => (props.align ? props.align : "center ")};
  margin: ${(props) => (props.m ? props.m : "")};
  @media screen and (max-width: 1355px) {
    font-size: ${(props) => props.fs1440 ? props.fs1440 : "120px"};
    line-height:115px;
  }
  @media screen and (max-width: 1190px) {
    font-size: ${(props) => props.fs972 ? props.fs972 : "calc(101px + (26 - 20) * ((100vw - 16px) / (1600 - 250)))"};
    line-height:95px;
  }
  @media screen and (max-width: 1045px) {
    font-size: ${(props) => props.fs1440 ? props.fs1440 : "90px"};
    line-height:80px;
  }
  @media screen and (max-width: 519px) {
    line-height:65px;
    padding: ${(props) => (props.p519 ? props.p519 : "50px 0 0")};
    font-size: ${(props) => props.fs419 ? props.fs419 : "calc(50px + (26 - 10) * ((100vw - 26px) / (1600 - 250)))"};
  }
`;
const SectionHeading = styled.h2`
color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
font-size: ${(props) => props.fs ? props.fs : "calc(75px + (26 - 22) * ((100vw - 146px) / (1600 - 250)))"};
  line-height:85px;
font-family: "P-B";
  width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  text-align: ${(props) => (props.align ? props.align : "center ")};
  margin: ${(props) => (props.m ? props.m : "")};
  @media screen and (max-width: 1220px) {
    font-size: ${(props) => props.fs1220 ? props.fs1220 : ""};
    padding: ${(props) => (props.p1220 ? props.p1220 : "50px 0")};
    line-height:65px;
  }
  @media screen and (max-width: 1130px) {
    font-size: ${(props) => props.fs972 ? props.fs972 : "calc(71px + (26 - 20) * ((100vw - 16px) / (1600 - 250)))"};
    line-height:65px;
  }
  @media screen and (max-width: 670px) {
    line-height:55px;
    padding: ${(props) => (props.p519 ? props.p519 : "40px 0 0")};
    font-size: ${(props) => props.fs419 ? props.fs419 : "calc(50px + (26 - 10) * ((100vw - 26px) / (1600 - 250)))"};
  }
  @media screen and (max-width: 497px) {
    line-height:50px;
    font-size: ${(props) => props.fs419 ? props.fs419 : "calc(40px + (26 - 10) * ((100vw - 26px) / (1600 - 250)))"};
    padding: ${(props) => (props.p519 ? props.p519 : "40px 0 0")};
  }
  @media screen and (max-width: 419px) {
    line-height:45px;
    padding: ${(props) => (props.p519 ? props.p519 : "40px 0 0")};
    font-size: ${(props) => props.fs419 ? props.fs419 : "calc(29px + (26 - 10) * ((100vw - 26px) / (1600 - 250)))"};
  }
`;
const SectionSubHeading = styled.h3`
  font-family: "P-B";
  font-size: ${(props) => (props.fs ? props.fs : "60px")};
  color: #fff;
  padding: ${(props) => (props.p ? props.p : "40px 0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* line-height: ${(props) => (props.lh ? props.lh : "50px")}; */
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  @media screen and (max-width: 1199px) {
    /* font-size: 30px; */
  }
  @media (max-width: 599px) {
    font-size: ${(props) => (props.fs599 ? props.fs599 : "50px")};
    padding: ${(props) => (props.p599 ? props.p599 : "")};
  }
  @media (max-width: 399px) {
    font-size: ${(props) => (props.fs399 ? props.fs399 : "")};
    padding: ${(props) => (props.p399 ? props.p399 : "")};
  }
`;

const BuyNow = styled.a`
cursor:pointer;
display:inline-flex;
align-items:center;
background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.textPrimary)};
color: ${(props) => (props.Color ? props.Color : props.theme.textPrimary)};
border-radius: 15px;
font-size:20px;
  padding: ${(props) => (props.p ? props.p : "8px 20px")};
margin-right: 15px;
margin-top:10px;
font-family:"P-M";
border:1px solid ${(props) => (props.brcolor ? props.brcolor : props.theme.textPrimary)};
position: relative;
  text-decoration: none;
  overflow: hidden;
  transition: 0.2s;
  span {
    position: absolute;
    display: block;
    &:nth-child(1) {
      top: 0;
      left: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, transparent,  ${(props) => (props.LineColor ? props.LineColor : props.theme.textPrimary)});
    }
    &:nth-child(2) {
      top: -100%;
      right: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(180deg, transparent,  ${(props) => (props.LineColor ? props.LineColor : props.theme.textPrimary)});
    }
    &:nth-child(3) {
      bottom: 0;
      right: -100%;
      width: 100%;
      height: 2px;
      background: linear-gradient(270deg, transparent,  ${(props) => (props.LineColor ? props.LineColor : props.theme.textPrimary)});
    }
    &:nth-child(4) {
      bottom: -100%;
      left: 0;
      width: 2px;
      height: 100%;
      background: linear-gradient(360deg, transparent,  ${(props) => (props.LineColor ? props.LineColor : props.theme.textPrimary)});
    }
  }
  &:hover {
    color: ${(props) => (props.BgHoverTextColor ? props.BgHoverTextColor : props.theme.textSecondary)};
    background: ${(props) => (props.BgHoverColors ? props.BgHoverColors : props.theme.textPrimary)};
    box-shadow: 0 0 15px ${(props) => (props.BgHoverColor ? props.BgHoverColor : props.theme.textPrimary)}, 0 0 1px ${(props) => (props.BgHoverColor ? props.BgHoverColor : props.theme.textPrimary)}, 0 0 15px;
    transition-delay: 0.8s;
    span:nth-child(1) {
      left: 100%;
      transition: 0.6s;
    }
    span:nth-child(2) {
      top: 100%;
      transition: 1s;
      transition-delay: 0.1s;
    }
    span:nth-child(3) {
      right: 100%;
      transition: 1s;
      transition-delay: 0.3s;
    }
    span:nth-child(4) {
      bottom: 100%;
      transition: 1s;
      transition-delay: 0.5s;
    }
  }

`
const SectionSmallHeading = styled.h3`
font-family: "P-R";
  font-size: ${(props) => (props.fs ? props.fs : "22px")};
  text-transform: ${(props) => (props.tt ? props.tt : "")};
  color: ${(props) => (props.color ? props.color : props.theme.textPrimary)};
  padding: ${(props) => (props.p ? props.p : "0px 0 0px 0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  @media (max-width: 1000px) {
    padding: ${(props) => (props.p1000 ? props.p1000 : "")};
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
  @media (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "")};
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  }
`;
const FooterImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 90px;
`;

const LogoText = styled.div`
  color: ${(props) => props.theme.textPrimary};
  font-family: "P-R";
  font-size: 18px;
  padding:20px 0 40px;
  max-width:80%;
  @media screen and (max-width:1220px){
    padding:0px 0 0px;
  }
  @media screen and (max-width:980px){
    font-size: 16px;
    padding:10px 0 0px;
  }
`;
const LogoImage = styled.a`
  display: flex;
  gap: 13px;
  align-items: center;
`;

export {BuyNow,Text,SectionSubtext,SectionHeading,FooterImage,LogoText,LogoImage,PageWrapper,SectionWrapper,P,SectionSubHeading,CustomContainer,SectionSmallHeading,Heading, SectionMainWrapper};
