import * as React from "react";
import { Dropdown } from "@mui/base/Dropdown";
import { Menu } from "@mui/base/Menu";
import { MenuButton } from "@mui/base/MenuButton";
import { MenuItem, menuItemClasses } from "@mui/base/MenuItem";
import { styled } from "@mui/system";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { Split } from "../../Pages/Swap/S1_Swap/style";
import { Grid, Input,  Typography } from "@mui/material";
import LoopIcon from "@mui/icons-material/Loop";

const TriggerButton = styled(MenuButton)`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const MenuDiv = styled(Menu)`
   background-color: #161616; 
  border: none;
`;
const ParentGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
`;
const ChildGrid = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #fff;
  border-radius: 30px;
  padding: 5px 10px;
  cursor: pointer;
`;

const InputField = styled(Input)`
  width: 100%;
  height: 25px;
  border: none;
  font-size: 12px;
  background-color: #fff;
  padding: 0 10px;
  border-radius: 5px;
  :focus {
    outline: none;
  }
  ::before {
    content: none;
    border-bottom: none;
  }
  ::after {
    content: none;
    border-bottom: none;
  }
`;
export default function MenuIntroduction() {
  const [selectedButton, setSelectedButton] = React.useState(2);
  const [selectedValue, setSelectedValue] = React.useState(localStorage.getItem('gwie') ? localStorage.getItem('gwie') : 16);
  const [customGiw, setcustomGiw] = React.useState(true);
  const [customGiwState, setcustomGiwState] = React.useState(1);

  const handleCustomOffClick = () => {
    setcustomGiw(false);
    selectedValue && localStorage.setItem('gwie', selectedValue);
    setcustomGiwState(2);
  };
  const handleCustomOnClick = () => {
    setcustomGiw(true);
    localStorage.removeItem('gwie');
    setcustomGiwState(1);
  };
  const handleButtonClick = (ChildGridIndex, value) => {
    setSelectedButton(ChildGridIndex);
    setSelectedValue(value);
  };
  const handleInputChange = (value) => {
    if (value) {
      setSelectedButton(4);
      setSelectedValue(value);
      !customGiw ? localStorage.setItem('gwie', value) : setSelectedValue(value)
    } else {
      setSelectedButton(2);
      setSelectedValue(16);
    }
  };
  React.useEffect(()=>{
    const gwei = localStorage.getItem('gwie')
    if(gwei){
      setSelectedValue(gwei)
      setSelectedButton(4);
      setcustomGiw(false);
      setcustomGiwState(2);
    }
    else if(!selectedValue){
      setSelectedValue(16)
    }
  },[customGiw])

  return (
    <Dropdown>
      <TriggerButton>
        <LocalGasStationIcon/>
      </TriggerButton>
      <MenuDiv slots={{ listbox: StyledListbox }}>
        <Split>
          <div style={{ display: "flex" }}>
            <LocalGasStationIcon sx={{ fontSize: "20px" }} />
            <Typography sx={{ fontSize: "16px" }}>Gas Preset</Typography>
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
            {selectedValue}
          </Typography>
          </div>
          <LoopIcon sx={{ fontSize: "20px" }} />
        </Split>
        <Split>
          <Typography sx={{ fontSize: "12px", padding: "0 10px" , fontFamily:"PoppinsR" }}>
            Select your preferred gas preset or enter a custom GWEI to execute
            transactions.
          </Typography>
        </Split>
        <ParentGrid>
          <ChildGrid
            item
            xs={4}
            style={{
              backgroundColor: selectedButton === 1 ? "#6FBA3F" : "transparent",
            }}
            onClick={() => handleButtonClick(1, 13)}
          >
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              Low
            </Typography>
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              13
            </Typography>
          </ChildGrid>
          <ChildGrid
            item
            xs={4}
            style={{
              backgroundColor: selectedButton === 2 ? "#6FBA3F" : "transparent",
            }}
            onClick={() => handleButtonClick(2, 16)}
          >
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              Medium
            </Typography>
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              16
            </Typography>
          </ChildGrid>
          <ChildGrid
            item
            xs={4}
            style={{
              backgroundColor: selectedButton === 3 ? "#6FBA3F" : "transparent",
            }}
            onClick={() => handleButtonClick(3, 19)}
          >
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              High
            </Typography>
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              19
            </Typography>
          </ChildGrid>
        </ParentGrid>
        <hr
          style={{
            height: "2px",
            width: "100%",
            background: "#fff",
            margin: "10px 0",
          }}
        />
            <Split>
              <Typography sx={{ fontSize: "12px" }}>
                Enter a custom GWEI to execute transactions
              </Typography>
            </Split>
            <InputField
              placeholder="i.e. 210"
              type="number"
              onKeyUp={(event) => {
                handleInputChange(event.target.value);
              }}
            />
        <Split>
          <Typography sx={{ fontSize: "12px" }}>
            Use custom GWEI one time only?
          </Typography>
        </Split>
        <Split>
          <ChildGrid
            item
            xs={4}
            style={{
              backgroundColor: customGiwState === 1 ? "#6FBA3F" : "transparent",
            }}
            onClick={() => handleCustomOnClick()}
          >
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              ON
            </Typography>
          </ChildGrid>
          <ChildGrid
            item
            xs={4}
            style={{
              backgroundColor: customGiwState === 2 ? "#6FBA3F" : "transparent",
            }}
            onClick={() => handleCustomOffClick()}
          >
            <Typography sx={{ fontSize: "12px", padding: "0 10px" }}>
              OFF
            </Typography>
          </ChildGrid>
        </Split>
        {/* <StyledMenuItem onClick={createHandleMenuClick('Profile')}>
          Profile
        </StyledMenuItem>
        <StyledMenuItem onClick={createHandleMenuClick('My account')}>
          Language settings
        </StyledMenuItem>
        <StyledMenuItem onClick={createHandleMenuClick('Log out')}>
          Log out
        </StyledMenuItem> */}
      </MenuDiv>
    </Dropdown>
  );
}

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledListbox = styled("ul")(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 12px;
  margin: 12px 0;
  max-width: 300px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  
  background: ${theme.palette.mode === "dark" ? grey[900] : "##ffffff26"};
//   border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : "#fff"};
  box-shadow: 0px 4px 30px ${
    theme.palette.mode === "dark" ? grey[900] : "#272626"
  };
  z-index: 1;
  `
);
