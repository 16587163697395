import { EthereumClient, w3mConnectors, w3mProvider } from "@web3modal/ethereum";
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { configureChains, createClient } from "wagmi";

import { ethMainNet,PulseChain } from "../Chains/Chain";
import { walletConnect } from "../Config";
import { Web3Modal } from "@web3modal/react";
import { WagmiConfig } from "wagmi";
import { publicProvider } from 'wagmi/providers/public';
import {  goerli} from 'wagmi/chains'
import { CalChain } from "../Chains/Chain";

const chains = [ CalChain,goerli];

const { provider } = configureChains( // Wagmi client
  chains,
  [
    w3mProvider({ projectId: walletConnect }),
    publicProvider(),
    jsonRpcProvider({
      rpc: (chain) => {
        // if (chain.id === PulseChain.id) return { http: PulseChain.rpcUrls.default }
        // if (chain.id === CalChain.id) return { http: CalChain.rpcUrls.default }
        if (chain.id === goerli.id) return { http: goerli.rpcUrls.default }
      },
    }),
  ],
);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ appName: "web3Modal", chains }),
  provider,
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiClient, chains);

const WagmiWallet = ({children}) => {
  return (
    <>
      <WagmiConfig client={wagmiClient}>
        {children}
      </WagmiConfig>
      <Web3Modal
        projectId={walletConnect}
        ethereumClient={ethereumClient}
        themeMode='light'
      />
    </>
  );
};

export default WagmiWallet;


export { wagmiClient, walletConnect, ethereumClient }