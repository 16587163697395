import { Container, Grid } from "@mui/material";
import React from "react";
import { Logo,MenuContainer,MenuLink,DropDown,MenuDiv, } from "./styles";
import WalletButton from "../singleNetworkButtons/WalletButton";
import NetworkBalance from "../singleNetworkButtons/MenuWalletButton/NetworkBalance";
import styled from "@emotion/styled";

const DashboardMenu = () => {
  return (
    <div style={{display: "contents"}}>
        <Container maxWidth="xl" >
          <Grid container  style={{padding: '10px 0',display: "contents"}}>
            <MenuContainer>
              <div>
                <NetworkBalance />
                <WalletButton 
                  isAllowance=""
                  allowanceCall=""
                  isLock=""
                  lockCall=""
                />
              </div>
            </MenuContainer>
          </Grid>
        </Container>
    </div>
  );
};

export default DashboardMenu;
