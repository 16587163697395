import { forwardRef, useEffect, useState } from "react";
import React from "react";
import Web3 from 'web3';

import Container from "@mui/material/Container";
import { Grid } from "@mui/material";
import { AiOutlineDown } from "react-icons/ai";

import slipage from "../../../../assets/images/slipage.png";
import swapicon from "../../../../assets/images/swapicon.png";
import swapArrow from "../../../../assets/images/swapArrow.png";
import pls from "../../../../assets/images/pls.png";
import floki from "../../../../assets/images/floki.png";
import inc from "../../../../assets/images/inc.png";
import timer from "../../../../assets/images/que.png";
import dai from "../../../../assets/images/dai.png";
import pulsexicon from "../../../../assets/images/pulsexicon.png";
import usdc from "../../../../assets/images/usdc.png";
import usdt from "../../../../assets/images/usdt.png";

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SettingsIcon from '@mui/icons-material/Settings';
import Footer from "../Components/Footer";
import Navbar from "../Components/Navbar";
import Minumum from "../Components/Minmium/Minumum";
import { P, SectionSmallHeading } from "../../../Styles/style";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

import { SettingSlip, DIvWrapper, InputDIv, InpuFieldSelect, SlipageControl, Wrapper, SwapDiv, InsideWrap, Split, MaxButton, Swaper, SelectedToken, Tokenimg, WrapDiv, SwapButton, SwapingButton, SlipageBox, PopupWrap, Popup, PopupContent, CloseButton, Inputoken, ExhangeInnerDiv, } from "./style";
import { FlokiTokenABI, RouterContractABI, RouterContractAddress, tokenABI, factoryContractAddress, factoryABI, PLSAddress, USDCAddress, DAIAddress, USDTAddress, contractAbi, FlokiTokenAddress } from "../../../../Abis&Ca";
import CustomButton from "../../../Components/CustomBtn";
import MenuIntroduction from "../../../Components/GassDropdown";
import SettingDropDown from "../../../Components/SettingDropdown";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Exchange = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formattedFirstBalance, setFormattedFirstBalance] = useState('');
  const [formattedSecondBalance, setFormattedSecondBalance] = useState('');
  const [balance, setBalance] = useState(0);
  const [userBalance, setUserBalance] = useState(0);
  const [balanceToken, setBalanceToken] = useState(0);
  const [UserTokenBalance, setUserTokenBalance] = useState(0);

  const [showResults, setShowResults] = useState(false);
  const [Trx, setTrx] = useState(false);
  const [Open, setOpen] = useState(false);
  const [gasPrice, setGasPrice] = useState('');
  const [useInputSlipage, setInputSlipage] = useState("0.1");
  const [useInputDeadline, setInputDeadline] = useState("15");

  const [InputTokenAddress, setInputTokenAddress] = useState('');
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [TokenName, setTokenName] = useState('');
  const [pairAddress, setPairAddress] = useState('0x20D7294E795B424AD8653168FCb3A791F4B44b21');
  const [PirceInUSD, setPirceInUSD] = useState('');
  const [PLSInUSD, setPLSInUSD] = useState('');
  const [PlsPrice, setPlsPrice] = useState('0');
  const [ShowPrice, setShowPrice] = useState('0');

  const [ExistToken, setExistToken] = useState('');
  const [importedTokenAddress, setImportedTokenAddress] = useState('');
  const [OpenError, setOpenError] = useState(false);
  const [OpenInfo, setOpenInfo] = useState(false);
  const [error, setError] = useState('');
  const [isConnected, setIsConnected] = useState(false);
  const [chainId, setChainId] = useState('');
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);

  const [BottomAddress, setBottomAddress] = useState(FlokiTokenAddress);
  const [UpperAddress, setUpperAddress] = useState(PLSAddress);
  const [UserAccount, setUserAccount] = useState("");
  const [Decimal, setDecimal] = useState("");

  const [isApproved, setIsApproved] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [liquidityExists, setLiquidityExists] = useState(false);
  const [errors, setErrors] = useState('');
  const [LiquidityMessage, setLiquidityMessage] = useState('');
  const toggleScreen = () => setIsOpen(!isOpen)
  // for dialog
  const handleSetMaxSlipage = () => setInputSlipage("100")
  const handleAutoSlipage = () => setInputSlipage("20")
  const [selectedOption1, setSelectedOption1] = useState({ title: 'WEth', icon: pls, address: PLSAddress, });
  const [selectedOption2, setSelectedOption2] = useState({ title: 'Test', icon: floki, address: FlokiTokenAddress, });

  const [toggle, setToggle] = useState(true);
  const [options, setOptions] = useState(() => {
    // Retrieve options from localStorage, or use a default value if not found
    const storedOptions = localStorage.getItem('importedTokens');
    const parsedOptions = storedOptions ? JSON.parse(storedOptions) : [
      // {
      //   title: 'FLOKI',
      //   icon: floki,
      //   address: '0x60C89f5eC551350235388b33d10643d46CaBE907',
      //   pairaddress: '0x71267038eC334a963aB7987f6dF4cF8441977FCE',
      //   disabled: selectedOption1.title === 'FLOKI' || selectedOption2.title === 'FLOKI'
      // },
      // {
      //   title: 'PLSX',
      //   icon: pulsexicon,
      //   pairaddress: '0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9',
      //   address: '0x95B303987A60C71504D99Aa1b13B4DA07b0790ab',
      //   disabled: selectedOption1.title === 'PLSX' || selectedOption2.title === 'PLSX'
      // },
      // {
      //   title: 'INC',
      //   icon: inc,
      //   pairaddress: '0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA',
      //   address: '0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d',
      //   disabled: selectedOption1.title === 'INC' || selectedOption2.title === 'INC'
      // },
      // {
      //   title: 'DAI',
      //   icon: dai,
      //   pairaddress: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
      //   address: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
      //   disabled: selectedOption1.title === 'DAI' || selectedOption2.title === 'DAI'
      // },
      // {
      //   title: 'WEth',
      //   icon: pls,
      //   address: '0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6',
      //   disabled: selectedOption1.title === 'PLS' || selectedOption2.title === 'PLS'
      // },
      // {
      //   title: 'USDC',
      //   icon: usdc,
      //   pairaddress: '0x6753560538ECa67617A9Ce605178F788bE7E524E',
      //   address: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
      //   disabled: selectedOption1.title === 'USDC' || selectedOption2.title === 'USDC'
      // },
      // {
      //   title: 'USDT',
      //   icon: usdt,
      //   address: '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
      //   disabled: selectedOption1.title === 'USDT' || selectedOption2.title === 'USDT'
      // },
      // {
      //   title: 'FLUFFY',
      //   icon: "",
      //   pairaddress: '0xB8331Abf8aCf6e9D33C69e34fdA213B7b97e9Bf2',
      //   address: '0xA9D4230b4899e6aaC0D84e540941B3832ABA3ba0',
      //   disabled: selectedOption1.title === 'FLUFFY' || selectedOption2.title === 'FLUFFY'
      // },
      // {
      //   title: 'RICH',
      //   icon: "",
      //   pairaddress: '0xCEc92bb8B196d3D797bdBB60c1029825C0CD478a',
      //   address: '0x5a266ae80608148F2351bf29903bD39B9d0D5Ced',
      //   disabled: selectedOption1.title === 'RICH' || selectedOption2.title === 'RICH'
      // },
    ];
    return parsedOptions.map(option => ({
      ...option,
    }));
  });

  // console.log("options",options);
  const toggleChecked = () => {
    setToggle((toggle) => !toggle);
  };

  const activeValue = toggle ? "UPPERFIELD" : "BOTTOMFIELD";

  const handleFirstInputChange = (e) => {
    const value = e.target.value;
    setFormattedFirstBalance(value);

  };
  const handleSecondInputChange = (e) => {
    const value = e.target.value;
    setFormattedSecondBalance(value);

  };
  const handleDialogOpen1 = () => setDialogOpen1(true);
  const handleDialogOpen2 = () => setDialogOpen2(true);
  const handleOptionSelect2 = (option) => {
    setSelectedOption2(option);
    setSelectedOption1(prevOption => (prevOption.title === option.title ? { title: '', icon: null, address: '' } : prevOption));
    setDialogOpen2(false);
    setBottomAddress(option.address);
    setPairAddress(option.pairaddress);
  };

  const handleOptionSelect1 = (option) => {
    setSelectedOption1(option);
    setSelectedOption2(prevOption => (prevOption.title === option.title ? { title: '', icon: null, address: '' } : prevOption));
    setDialogOpen1(false);
    setUpperAddress(option.address);
    setPairAddress(option.pairaddress);
  };
  const handleDialogClose1 = () => setDialogOpen1(false);
  const handleDialogClose2 = () => setDialogOpen2(false);

  const firstSelectedTokenProps = {
    onClick: toggle ? handleDialogOpen1 : handleDialogOpen2,
    imgSrc: toggle ? selectedOption1.icon : selectedOption2.icon,
    tokenValue: toggle ? selectedOption1.title : selectedOption2.title,
  };
  const secondSelectedTokenProps = {
    onClick: toggle ? handleDialogOpen2 : handleDialogOpen1,
    imgSrc: toggle ? selectedOption2.icon : selectedOption1.icon,
    tokenValue: toggle ? selectedOption2.title : selectedOption1.title,
  };
  const firstInputProps = {
    type: "number",
    placeholder: "0.00",
    value: toggle ? formattedFirstBalance : formattedSecondBalance,
    onChange: toggle ? handleFirstInputChange : handleSecondInputChange,
  };

  const secondInputProps = {
    type: "number",
    placeholder: "0.00",
    value: toggle ? formattedSecondBalance : formattedFirstBalance,
    onChange: toggle ? handleSecondInputChange : handleFirstInputChange,
  };

  const handleSlipage = (value) => {
    const slipageValue = value;
    console.log("value : ",value)
    if (slipageValue <= 100) {
      setInputSlipage(slipageValue);
    } else {
      setInputSlipage("100");
    }
  };
  const handleDeadline = (value) => {
    const deadLine = value;
    setInputDeadline(deadLine);
  };

  useEffect(() => {
    if (selectedOption1.address === PLSAddress) {
      const price = PLSInUSD * formattedFirstBalance;
      const formattedPrice = price.toFixed(10);
      setPlsPrice(formattedPrice);
    } else if (selectedOption1.address === USDCAddress || selectedOption1.address === DAIAddress || selectedOption1.address === USDTAddress) {
      setPlsPrice(formattedFirstBalance);
    } else {
      const price = PirceInUSD * formattedFirstBalance;
      const formattedPrice = price.toFixed(10);
      setPlsPrice(formattedPrice);
    }
    if (selectedOption2.address === USDCAddress || selectedOption2.address === DAIAddress || selectedOption2.address === USDTAddress) {
      setShowPrice(formattedSecondBalance);
    } else if (selectedOption2.address === PLSAddress) {
      const price = PLSInUSD * formattedSecondBalance;
      const formattedPrice = price.toFixed(10);
      setPlsPrice(formattedPrice);
    } else {
      // console.log("PirceInUSD", PirceInUSD);
      const price = PirceInUSD * formattedSecondBalance;
      const formattedPrice = price.toFixed(10);
      // console.log("formattedPrice", formattedPrice);
      setShowPrice(formattedPrice);
    }
    const fetchUserNativeBalance = async () => {
      if (web3 && UserAccount) {
        try {
          if (selectedOption1.address === PLSAddress) {
            const balance = await web3.eth.getBalance(UserAccount);

            const formattedBalance = web3.utils.fromWei(balance, 'ether');
            const balanceNumber = Number(formattedBalance);
            const formattedBalances = balanceNumber.toFixed(3);
            const truncatedBalance = formattedBalances.substring(0, 22);
            setUserBalance(truncatedBalance);
            setBalance(truncatedBalance);
          } else {
            if (selectedOption1.address === USDCAddress || selectedOption1.address === USDTAddress) {
              const web3 = new Web3(window.ethereum);
              const tokenContract = new web3.eth.Contract(FlokiTokenABI, UpperAddress);
              const tokenBalance = await tokenContract.methods.balanceOf(UserAccount).call();
              setUserBalance(tokenBalance / 1e6);
              setBalance(tokenBalance / 1e6);
            } else {
              const tokenContract = new web3.eth.Contract(FlokiTokenABI, UpperAddress);
              const tokenBalance = await tokenContract.methods.balanceOf(UserAccount).call();
              const balanceNumber = Number(tokenBalance / 1e18);
              const formattedBalance = balanceNumber.toFixed(3);
              const truncatedBalance = formattedBalance.substring(0, 22);
              setUserBalance(truncatedBalance);
              setBalance(truncatedBalance);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    const fetchBalance = async () => {
      try {
        if (selectedOption2.address === PLSAddress) {
          const balance = await web3.eth.getBalance(UserAccount);
          const formattedBalance = web3.utils.fromWei(balance, 'ether');
          const balanceNumber = Number(formattedBalance);
          const formattedBalances = balanceNumber.toFixed(3);
          const truncatedBalance = formattedBalances.substring(0, 22);
          setBalanceToken(formattedBalance);
          setUserTokenBalance(truncatedBalance);
        }
        else {
          if (selectedOption2.address === USDCAddress || selectedOption2.address === USDTAddress) {
            const web3 = new Web3(window.ethereum);
            const tokenContract = new web3.eth.Contract(FlokiTokenABI, BottomAddress);
            const tokenBalance = await tokenContract.methods.balanceOf(UserAccount).call();
            setBalanceToken(tokenBalance / 1e6);
            setUserTokenBalance(tokenBalance / 1e6);
          } else {
            const web3 = new Web3(window.ethereum);
            // console.log("BottomAddress",BottomAddress);
            const tokenContract = new web3.eth.Contract(FlokiTokenABI, BottomAddress);
            const DecimalValue = await tokenContract.methods.decimals().call();
            setDecimal(DecimalValue);
            const tokenBalance = await tokenContract.methods.balanceOf(UserAccount).call();
            // console.log("tokenBalance",tokenBalance / (10 ** Decimal));
            // setBalanceToken(tokenBalance / 1e18);
            setBalanceToken(tokenBalance / (10 ** Decimal));
            const balanceNumber = Number(balanceToken);
            const formattedBalance = balanceNumber.toFixed(3);
            const truncatedBalance = formattedBalance.substring(0, 22);
            setUserTokenBalance(truncatedBalance);
          }
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };
    fetchUserNativeBalance();
    fetchBalance();
    const fetchGasPrice = async () => {
      try {
        const web3 = new Web3('https://goerli.infura.io/v3/5757d08e76ea4597ba83a4e6b955f01f'); // Replace with the Pulse chain RPC endpoint
        const gasPriceWei = await web3.eth.getGasPrice();
        const gasPriceGwei = web3.utils.fromWei(gasPriceWei, 'gwei');
        setGasPrice(gasPriceGwei);
      } catch (error) {
        console.error('Error fetching gas price:', error);
      }
    };

    fetchGasPrice();

  }, [formattedSecondBalance, PLSInUSD, PlsPrice, PirceInUSD, selectedOption1, selectedOption2, formattedFirstBalance, UserAccount, BottomAddress, balance, userBalance, balanceToken, UserTokenBalance,]);
  const formatBalance = () => {
    const balanceNumber = Number(activeValue === "UPPERFIELD" ? balance : balanceToken);
    const formattedBalance = balanceNumber.toFixed(3);
    const truncatedBalance = formattedBalance.substring(0, 22);

    if (!activeValue !== "UPPERFIELD") {
      if (selectedOption2.address === USDCAddress || selectedOption2.address === DAIAddress || selectedOption2.address === USDTAddress) {
        setShowPrice(formattedSecondBalance);
      } else {
        const price = PirceInUSD * formattedSecondBalance;
        const formattedPrice = price.toFixed(10);
        setShowPrice(formattedPrice);
      }
    }
    if (activeValue === "UPPERFIELD") {
      setFormattedFirstBalance(truncatedBalance);
      if (selectedOption1.address === PLSAddress || selectedOption2.address === PLSAddress) {
        const price = PLSInUSD * formattedFirstBalance;
        const formattedPrice = price.toFixed(10);
        setPlsPrice(formattedPrice);
      } else if (selectedOption1.address === USDCAddress || selectedOption1.address === DAIAddress || selectedOption1.address === USDTAddress) {
        setPlsPrice(formattedFirstBalance);
      } else {
        const price = PirceInUSD * formattedFirstBalance;
        const formattedPrice = price.toFixed(10);
        setPlsPrice(formattedPrice);
      }
    } else {
      setFormattedSecondBalance(truncatedBalance);
    }
  };
  const handleCalculation = () => {

    const web3 = new Web3('https://goerli.infura.io/v3/5757d08e76ea4597ba83a4e6b955f01f'); // Replace with the Pulse chain RPC endpoint
    let outputTokenAddress = toggle ? UpperAddress : BottomAddress;
    let inputTokenAddress = toggle ? BottomAddress : UpperAddress;
    if (activeValue === "UPPERFIELD") {
      if (formattedFirstBalance === '' || isNaN(Number(formattedFirstBalance))) {
        return;
      }
    } else if (activeValue === "BOTTOMFIELD") {
      if (formattedSecondBalance === '' || isNaN(Number(formattedSecondBalance))) {
        return;
      }
    }
    const inputAmountWei = web3.utils.toWei(toggle ? formattedFirstBalance : formattedSecondBalance);
    const routerContract = new web3.eth.Contract(RouterContractABI, RouterContractAddress);

    routerContract.methods.getAmountsOut(inputAmountWei, [outputTokenAddress, inputTokenAddress])
      .call()
      .then(amounts => {
        if (selectedOption2.address === USDCAddress || selectedOption2.address === USDTAddress) {
          const estimatedAmountOutWei = amounts[1];
          // const truncatedBalance = (estimatedAmountOutWei / (10 ** Decimal)).toString();
          const truncatedBalance = (estimatedAmountOutWei / 1e6).toString();
          const estimatedAmountOutHumanReadable = web3.utils.fromWei(estimatedAmountOutWei);
          const balanceNumber = Number(estimatedAmountOutHumanReadable);
          const formattedBalance = balanceNumber.toFixed(5);
          if (activeValue == "UPPERFIELD") {
            setFormattedSecondBalance(truncatedBalance);
          } else {
            setFormattedFirstBalance(formattedBalance);
          }
        } else {
          const estimatedAmountOutWei = amounts[1];
          const estimatedAmountOutHumanReadable = (estimatedAmountOutWei / (10 ** Decimal));
          // const estimatedAmountOutHumanReadable = (estimatedAmountOutWei / 1e18);
          const balanceNumber = Number(estimatedAmountOutHumanReadable);
          const formattedBalance = balanceNumber.toFixed(5);
          const truncatedBalance = formattedBalance.substring(0, 22);
          // console.log("truncatedBalance",truncatedBalance / 1e18);
          if (activeValue == "UPPERFIELD") {
            setFormattedSecondBalance(truncatedBalance);
          } else {
            setFormattedFirstBalance(truncatedBalance);
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };
  handleCalculation();

  const sendTransaction = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const contract = new web3.eth.Contract(RouterContractABI, RouterContractAddress);
      const minSlippagePercentage = 0.01; // Minimum acceptable slippage percentage
      const slippageDecimal = useInputSlipage / 100;
      const expectedOutputAmount = '1000000000000000000';
      const slippageAmount = expectedOutputAmount * slippageDecimal;
      let amountOutMin = expectedOutputAmount - slippageAmount;
      if (amountOutMin < 0 || slippageDecimal < minSlippagePercentage) {
        throw new Error('Invalid amountOutMin or slippagePercentage');
      }
      const path = [UpperAddress, BottomAddress]; // Reversed the token addresses for selling
      const to = UserAccount; // The address where you want to receive the tokens
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const deadline = currentTime + (useInputDeadline * 60); // Calculate deadline in seconds
      const transaction = await web3.eth.sendTransaction({
        from: UserAccount,
        to: RouterContractAddress,
        value: web3.utils.toWei(formattedFirstBalance, 'ether'), // The amount of ETH you want to swap
        gas: '500000', // Adjust the gas limit as needed
        gasPrice: web3.utils.toWei(gasPrice, 'gwei'), // Adjust the gas price as needed
        data: contract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(
          amountOutMin.toString(),
          path,
          to,
          deadline
        ).encodeABI(),
      });
      console.log('Transaction hash:', transaction.transactionHash);
      setTrx(transaction.transactionHash);
      setOpen(true);
    } catch (error) {
      console.error('Error sending transaction:', error);
    }
  };
  const sellTokens = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.enable();
      const contract = new web3.eth.Contract(RouterContractABI, RouterContractAddress);
      const amountInWei = web3.utils.toWei(formattedSecondBalance.toString()); // Convert the input amount to Wei
      const path = [BottomAddress, UpperAddress]; // Swap path: Floki to ETH
      const to = UserAccount; // The address where you want to receive the ETH
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const deadline = currentTime + (useInputDeadline * 60); // Calculate deadline in seconds
      const transaction = await web3.eth.sendTransaction({
        from: UserAccount,
        to: RouterContractAddress,
        value: 0, // No ETH value needed for selling tokens
        gas: '500000', // Adjust the gas limit as needed
        gasPrice: web3.utils.toWei(gasPrice, 'gwei'), // Adjust the gas price as needed
        data: contract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(
          amountInWei,
          '0', // Setting the minimum output amount to 0
          path,
          to,
          deadline
        ).encodeABI(),
      });
      console.log('Transaction hash:', transaction.transactionHash);
      setTrx(transaction.transactionHash);
      setOpen(true);
    } catch (error) {
      console.error('Error sending transaction:', error);
    }
  };
  const web3 = new Web3(window.ethereum);
  const amountToApprove = '100000000000000000000000000000';
  const tokenContract = new web3.eth.Contract(FlokiTokenABI, BottomAddress);

  const handleApproval = async () => {
    setIsApproving(true);
    try {
      const transaction = await tokenContract.methods.approve(RouterContractAddress, amountToApprove)
        .send({ from: UserAccount });
      console.log('Approval transaction:', transaction);
      setIsApproved(true);
    } catch (error) {
      console.error('Error:', error);
    }
    setIsApproving(false);
  }
  const handleSwapClick = () => {
    if (toggle) {
      sendTransaction();
    } else {
      sellTokens();
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    checkWalletConnection();
    getChainId();

    if (window.ethereum) {
      try {
        window.ethereum.on('accountsChanged', handleAccountsChanged);
        window.ethereum.on('chainChanged', handleChainChanged);
      } catch (error) {
        console.log('Error occurred while setting up event listeners:', error);
      }
    } else {
      console.log('MetaMask is not available.');
    }

    return () => {
      if (window.ethereum) {
        try {
          window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
          window.ethereum.removeListener('chainChanged', handleChainChanged);
        } catch (error) {
          console.log('Error occurred while removing event listeners:', error);
        }
      }
    };
  }, [isConnected]);
  const checkWalletConnection = async () => {
    if (window.ethereum) {
      try {
        // Set up the event listener for account changes
        window.ethereum.on('accountsChanged', (accounts) => {
          setUserAccount(accounts[0]);
          setIsConnected(accounts.length > 0);
        });

        const accounts = await web3.eth.getAccounts();
        setUserAccount(accounts[0]);
        setIsConnected(accounts.length > 0);
      } catch (error) {
        console.log('Error occurred while checking wallet connection:', error);
      }
    }
  };

  const getChainId = async () => {
    if (window.ethereum) {
      try {
        const chainId = await window.ethereum.request({ method: 'eth_chainId' });
        setChainId(chainId);
      } catch (error) {
        console.log('Error occurred while getting chain ID:', error);
      }
    }
  };
  const handleAccountsChanged = (accounts) => {
    setIsConnected(accounts.length > 0);
  };

  const handleChainChanged = (chainId) => {
    setChainId(chainId);
    if (chainId !== '0x5') {
      setIsConnected(false);
    }
  };

  const handleConnect = async () => {
    if (chainId !== '0x5') {
      setOpenError(true);
      setError('Invalid Network. Please Switch to the  Calchain network.');
      return;
    }
    if (window.ethereum) {
      try {
        await window.ethereum.enable();
        checkWalletConnection();
        setError('');
      } catch (error) {
        console.log('Error occurred while enabling MetaMask:', error);
      }
    } else {
      console.log('MetaMask is not available.');
    }
  };
  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError(false);
  };
  const handleInfoError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenInfo(false);
  };

  const fetchTokenInfo = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.enable();
      }
      const web3 = new Web3(window.ethereum);
      const tokenContract = new web3.eth.Contract(tokenABI, InputTokenAddress);
      const symbol = await tokenContract.methods.symbol().call();
      const Name = await tokenContract.methods.name().call();
      if (!symbol || !Name) {
        console.log('Token does not exist');
        return;
      }
      setTokenName(Name);
      setTokenSymbol(symbol);
      const factoryContract = new web3.eth.Contract(factoryABI, factoryContractAddress);
      const pair = await factoryContract.methods.getPair(InputTokenAddress, UpperAddress).call();
      setPairAddress(pair);
      setImportedTokenAddress(InputTokenAddress);
    } catch (error) {
      console.error(error);
    }
  };
  const importToken = (address) => {
    const existingToken = options.find((option) => option.address === address);
    if (existingToken) {
      setExistToken(existingToken.title);
      setOpenInfo(true);
      console.log('Token already exists:', existingToken.title);
      return;
    }
    const newToken = { title: tokenSymbol, icon: null, pairaddress: pairAddress, address, disabled: false };
    const updatedOptions = [...options, newToken];
    setOptions(updatedOptions);
    localStorage.setItem('importedTokens', JSON.stringify(updatedOptions));
    setBottomAddress(address);
    setTimeout(() => {
      setInputTokenAddress('');
      setTokenSymbol('');
      setImportedTokenAddress('');
    }, 5000);
  };
  useEffect(() => {
    return () => {
      localStorage.removeItem('importedTokens');
    };
  }, []);
  fetchTokenInfo();

  const [pooledWPLSAddress, setPooledWPLSAddress] = useState('');
  const [pooledTokenAddress, setPooledTokenAddress] = useState("");
  const checkLiquidity = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(RouterContractABI, RouterContractAddress);
      const amountsOut = await contract.methods.getAmountsOut(1, [UpperAddress, BottomAddress]).call();
      const amountsIn = await contract.methods.getAmountsIn(1, [UpperAddress, BottomAddress]).call();
      const liquidityExists = amountsOut[1] > 0 || amountsIn[0] > 0;
      setLiquidityExists(liquidityExists);
      if (!liquidityExists) {
        setLiquidityMessage('Insufficient liquidity for this trade.');
      } else {
        setLiquidityMessage('');
      }
    } catch (error) {
      console.error('Error checking liquidity:', error);
      setLiquidityMessage('Insufficient liquidity for this trade.', error);
      setErrors('An error occurred while checking liquidity');
    }
  };
  checkLiquidity();
  const fetchPLSPriceInUSD = async () => {
    try {
      const web3 = new Web3('https://goerli.infura.io/v3/5757d08e76ea4597ba83a4e6b955f01f');
      // console.log("pairAddress", pairAddress);
      const contract = new web3.eth.Contract(contractAbi, pairAddress);
      const reserves = await contract.methods.getReserves().call();
      // console.log("reserves", reserves);
      contract.methods.getReserves().call()
        .then(reserves => {
          const token0 = contract.methods.token0().call();
          const token1 = contract.methods.token1().call();
          return Promise.all([token0, token1])
            .then(tokens => {
              setPooledWPLSAddress(tokens[0]);
              setPooledTokenAddress(tokens[1]);
              setError('');
            });
        })
        .catch(error => {
          setError(error.message);
        });

      let PooledNativePLS, PooledTokens;
      const FirstPooled = parseFloat(web3.utils.fromWei(reserves[0], 'ether'));
      const SecondPooled = parseFloat(web3.utils.fromWei(reserves[1], 'ether'));

      if (FirstPooled < SecondPooled ) {
        PooledNativePLS = web3.utils.fromWei(reserves[0], 'ether');
        PooledTokens = web3.utils.fromWei(reserves[1], 'ether');
      } else {
        PooledNativePLS = web3.utils.fromWei(reserves[1], 'ether');
        PooledTokens = web3.utils.fromWei(reserves[0], 'ether');
      }

      // console.log("PooledNativePLS", PooledNativePLS);
      // console.log("PooledTokens", PooledTokens);

      const priceInPLS = PooledNativePLS / PooledTokens;

      const exchangeRatePLStoUSD = await fetchExchangeRatePLStoUSD(); // Replace with the function to fetch the exchange rate
      setPLSInUSD(exchangeRatePLStoUSD);
      const priceUSD = priceInPLS * exchangeRatePLStoUSD;
      const decimalNumber = priceUSD.toFixed(10);
      setPirceInUSD(decimalNumber);
    } catch (error) {
      console.error('Error fetching PLS price:', error);
    }
  };
  // const [pooledWPLSAddress, setPooledWPLSAddress] = useState('0xA1077a294dDE1B09bB078844df40758a5D0f9a27');
  // const [pooledTokenAddress, setPooledTokenAddress] = useState(pairAddress);
  const [pooledWWPLS, setPooledWPLS] = useState('');
  const [pooledTToken, setPooledToken] = useState('');

  // useEffect(() => {
  //   // Initialize web3

  //   const web3 = new Web3('https://goerli.infura.io/v3/5757d08e76ea4597ba83a4e6b955f01f');
  //   function getPooledTokens() {
  //     const contract = new web3.eth.Contract(contractAbi, pairAddress);
  //     console.log("pairAddress", pairAddress);
  //     contract.methods.getReserves().call()
  //       .then(reserves => {
  //         const token0 = contract.methods.token0().call();
  //         const token1 = contract.methods.token1().call();
  //         return Promise.all([token0, token1])
  //           .then(tokens => {
  //             const token0Address = tokens[0];
  //             const token1Address = tokens[1];
  //             console.log("token0Address", token0Address);
  //             console.log("token1Address", token1Address);
  //             if (token0Address === "0xA1077a294dDE1B09bB078844df40758a5D0f9a27" || token1Address === "0xA1077a294dDE1B09bB078844df40758a5D0f9a27") {

  //             }
  //             let pooledWPLS;
  //             let pooledToken;

  //             if (token0Address === pooledWPLSAddress) {
  //               pooledWPLS = token0Address;
  //               pooledToken = token1Address;
  //             } else if (token1Address === pooledWPLSAddress) {
  //               pooledWPLS = token1Address;
  //               pooledToken = token0Address;
  //             } else {
  //               throw new Error("The provided addresses are not associated with the pooled tokens.");
  //             }

  //             setPooledWPLS(pooledWPLS);
  //             setPooledToken(pooledToken);
  //             setError('');
  //           });
  //       })
  //       .catch(error => {
  //         setPooledWPLS('');
  //         setPooledToken('');
  //         setError(error.message);
  //       });
  //   }

  //   getPooledTokens();
  // }, []);

  // console.log("pooledWPLS", pooledWWPLS);
  // console.log("pooledToken", pooledTToken);
  const fetchExchangeRatePLStoUSD = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=pulsechain&vs_currencies=usd');
      const data = await response.json();
      const exchangeRate = data.pulsechain.usd;
      localStorage.setItem('lastExchangeRatePLStoUSD', exchangeRate);
      return exchangeRate;
    } catch (error) {
      const lastExchangeRate = localStorage.getItem('lastExchangeRatePLStoUSD');
      if (lastExchangeRate) {
        return parseFloat(lastExchangeRate); // Convert the stored value to a number
      } else {
        console.error('Error fetching exchange rate:', error);
        return null;
      }
    }
  };
  fetchPLSPriceInUSD();

  return (
    <>
      {/* <Wrapper> */}
        {/* <Navbar /> */}
        <Dialog
          PaperProps={{
            style: {
              minWidth: "400px",
              minHeight: "400px",
              background: "linear-gradient(0deg, rgb(0 0 0 / 89%) 40%, rgb(0, 0, 0) 78%)",
              border: "1px solid #ffffff2b",
            },
          }}
          open={dialogOpen1}
          onClose={handleDialogClose1}
        >
          <Split>
            <DialogContent style={{ color: "#fff" }}>Select Token</DialogContent>
            <DialogActions>
              <Button style={{ color: "#fff" }} onClick={handleDialogClose1}>
                X
              </Button>
            </DialogActions>
          </Split>
          <DialogActions>
            <Inputoken
              type="text"
              placeholder="Enter token address"
              value={InputTokenAddress}
              onChange={(e) => setInputTokenAddress(e.target.value)}
            />

          </DialogActions>
          <Split p="0 20px 0 0">
            {importedTokenAddress && (
              <>
                <P p="0 20px " m="0">Token : {tokenSymbol}</P>
                <SwapingButton p="7px 0" maxWidth="30%" onClick={() => importToken(importedTokenAddress)}>Import</SwapingButton>
              </>
            )}
          </Split>
          <DialogContent>
            {options.map((option) => (
              <WrapDiv>
                <Tokenimg
                  // style={{ opacity: option.disabled ? 0.5 : 1 }} 
                  src={option.icon || timer} alt={option.title} className="dialog-icon" />
                <Button
                  key={option.title}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    color: "#fff",
                    // opacity: option.disabled ? 0.5 : 1, // Apply opacity to disabled options
                  }}
                  onClick={() => handleOptionSelect1(option)}
                // disabled={option.disabled}
                >
                  &nbsp;&nbsp;
                  {option.title}
                </Button>
              </WrapDiv>
            ))}
          </DialogContent>
        </Dialog>

        <Dialog
          PaperProps={{
            style: {
              minWidth: "400px",
              minHeight: "400px",
              background: "linear-gradient(0deg, rgb(0 0 0 / 89%) 40%, rgb(0, 0, 0) 78%)",
              border: "1px solid #ffffff2b",
            },
          }}
          open={dialogOpen2}
          onClose={handleDialogClose2}
        >
          <Split>
            <DialogTitle style={{ color: "#fff" }}>Select Token</DialogTitle>
            <DialogActions>
              <Button style={{ color: "#fff" }} onClick={handleDialogClose2}>X</Button>
            </DialogActions>
          </Split>
          <DialogActions>
            <Inputoken
              type="text"
              placeholder="Enter token address"
              value={InputTokenAddress}
              onChange={(e) => setInputTokenAddress(e.target.value)}
            />
          </DialogActions>
          <Split p="0 20px 0 0">
            {importedTokenAddress && (
              <>
                <P p="0 20px " m="0">Token: {tokenSymbol}</P>
                <SwapingButton p="7px 0" maxWidth="30%" onClick={() => importToken(importedTokenAddress)}>Import</SwapingButton>
              </>
            )}
          </Split>
          <DialogContent>
            {options.map((option) => (
              <WrapDiv>
                <Tokenimg
                  // style={{ opacity: option.disabled ? 0.5 : 1 }} 
                  src={option.icon || timer} alt={option.title} className="dialog-icon" />
                <Button
                  key={option.title}
                  style={{
                    fontSize: "18px",
                    display: "flex",
                    flexDirection: "column",
                    color: "#fff",
                    // opacity: option.disabled ? 0.5 : 1, // Apply opacity to disabled options
                  }}
                  onClick={() => handleOptionSelect2(option)}
                // disabled={option.disabled}
                >
                  &nbsp;&nbsp;
                  {option.title}
                </Button>
              </WrapDiv>
            ))}
          </DialogContent>
        </Dialog>
        {/* <Container maxwidth="xl">
          <InsideWrap>
          <Grid container justifyContent="center">
            <Grid item xs={12} sm={11} md={5} >
                <SwapDiv> */}
                  <ExhangeInnerDiv style={{ textAlign: "center" }} >
                    {/* <img src={swapArrow} /> */}
                    <div style={{ display: "flex" , alignItems:"center"}}>
                    <P color="#93A0A8" fs="14px" p="0" m="0"> Network: </P>
                    <SectionSmallHeading fs="16px" align="center" p="0px"> &nbsp;Ethereum </SectionSmallHeading>
                    </div>
                    <div>
                    <MenuIntroduction/>
                    <SettingDropDown slipage={useInputSlipage} deadline={useInputDeadline} funcDeadline={handleDeadline} funcSlipage={handleSlipage} />
                  </div>
                  </ExhangeInnerDiv>
                  <Swaper>
                    <Split>
                      <P color="#93A0A8" fs="14px" p="0" m="0"> Pay </P>
                      {activeValue === "UPPERFIELD" ? (
                        <P fs="14px" color="#93A0A8" p="0" m="0">  Balance: {userBalance} </P>
                    ) : (
                        <P fs="14px" color="#93A0A8" p="0" m="0">  Balance: {UserTokenBalance} </P>
                    )}
                    </Split>
                    <InputDIv>
                      <SelectedToken {...firstSelectedTokenProps}>
                        <Tokenimg src={firstSelectedTokenProps.imgSrc || timer} />
                        &nbsp;&nbsp;
                        {firstSelectedTokenProps.tokenValue}
                        {/* &nbsp;&nbsp; */}
                        <AiOutlineDown style={{ margin: "0 4px", color: "#fff" }} />
                      </SelectedToken>
                    <div style={{display:"flex",alignItems:"center"}}>
                      <InpuFieldSelect {...firstInputProps} />
                      <CustomButton onClick={formatBalance}>Max</CustomButton>
                      </div>
                    </InputDIv>
                    
                  </Swaper>
                  <div style={{ display: "flex", justifyContent: "center", position: "relative", textAlign: "center", }} >
                    <SwapButton>
                      <img onClick={toggleChecked} src={swapicon} alt="Swap Icon" />
                    </SwapButton>
                  </div>
                  <Swaper>
                    <Split>
                    <P color="#93A0A8" fs="14px" p="0" m="0">Receive </P>
                    {activeValue === "BOTTOMFIELD" ? (
                        <P fs="14px" color="#93A0A8" p="0" m="0">Balance: {userBalance} </P>
                    ) : (
                        <P fs="14px" color="#93A0A8" p="0" m="0"> Balance: {UserTokenBalance}</P>
                    )}
                    </Split>
                    <InputDIv>
                      <SelectedToken  {...secondSelectedTokenProps}>
                        <Tokenimg src={secondSelectedTokenProps.imgSrc || timer} />
                        &nbsp;&nbsp;
                        {secondSelectedTokenProps.tokenValue}
                        <AiOutlineDown style={{fontSize:"47px",margin: "0 4px", color: "#fff" }} />
                      </SelectedToken>
                      <InpuFieldSelect {...secondInputProps} />
                    </InputDIv>
                    
                  </Swaper>
                  <Split p="10px 0">
                    <P fs="14px" color="#93A0A8" p="0px 0" m="0">
                    Slippage Tolerance
                    </P>
                      <P fs="14px" p="0px 10px 0 0" m="0">  {useInputSlipage} %</P>
                  </Split>
                  <div>
                    {(chainId !== '0x5' || !isConnected) ? (
                      <CustomButton sx={{width:"100%"}} onClick={handleConnect}>Connect Wallet</CustomButton>
                    ) : (
                      <>
                        {activeValue === "BOTTOMFIELD" ? (
                          <div>
                            {!isApproved && (
                              <SwapingButton onClick={handleApproval} disabled={isApproving}>
                                {isApproving ? 'Approving...' : 'Approve'}
                              </SwapingButton>
                            )}
                            {isApproved && (
                              <SwapingButton onClick={handleSwapClick}> Swap </SwapingButton>
                            )}
                          </div>
                        ) : (
                          <>
                            {LiquidityMessage && <SwapingButton style={{ opacity: "0.5" }}>{LiquidityMessage}</SwapingButton>}
                            {!LiquidityMessage && <SwapingButton onClick={handleSwapClick}>Swap</SwapingButton>}

                          </>
                        )}
                      </>
                    )}
                  </div>
                  {isOpen && (
                    <PopupWrap>
                      <Popup>
                        <PopupContent>
                          <Split>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <SettingSlip src={slipage} />&nbsp;
                              <P fs="15px" p="20px 0" m="0"> Settings </P>
                            </div>
                            <CloseButton onClick={toggleScreen}>X</CloseButton>
                          </Split>
                          <P color="#ffffff92" p="0 0 10px" m="0" fs="12px">Slippage Tolerance</P>
                          <Split>
                            <SwapingButton onClick={handleAutoSlipage} p="2px 0" maxWidth="20%"> Auto</SwapingButton>
                            &nbsp;
                            <SwapingButton onClick={handleSetMaxSlipage} p="2px 0" maxWidth="20%">100%</SwapingButton>
                            <label htmlFor="text"></label>
                            <SlipageControl
                              onChange={handleSlipage}
                              type="number"
                              placeholder="2.00%"
                              value={useInputSlipage}
                            />
                          </Split>
                          <P color="#ffffff92" p="20PX 0 10px" m="0" fs="12px">Transaction Deadline</P>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <label htmlFor="text"></label>
                            <SlipageControl
                              ml="0"
                              maxWidth="30%"
                              onChange={handleDeadline}
                              type="number"
                              placeholder="15"
                              value={useInputDeadline}
                            />
                            <P align="left" color="#ffffff92" p="0 0 10px 10px" m="0" fs="12px">minutes</P>
                          </div>
                        </PopupContent>
                      </Popup>
                    </PopupWrap>
                  )}
                  {/* {showResults ? <Minumum /> : null} */}
                {/* </SwapDiv>
              </Grid>
            </Grid>
          </InsideWrap>
           <Footer /> 
        </Container> */}
      {/* </Wrapper> */}
      {/* Errors */}
      <Snackbar open={OpenInfo} autoHideDuration={2000} onClose={handleInfoError}>
        <Alert onClose={handleInfoError} severity="info" sx={{ width: '100%' }}>
          <>{ExistToken} Already exists </>
        </Alert>
      </Snackbar>
      <Snackbar open={OpenError} autoHideDuration={2000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          <>{error}</>
        </Alert>
      </Snackbar>
      <a style={{ textTransform: 'lowercase', color: "#fff" }} href={`https://scan.pulsechain.com/tx/${Trx}`} target="blank">
        <Snackbar open={Open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            https://scan.pulsechain.com/tx/{Trx}

          </Alert>
        </Snackbar>
      </a>
    </>
  );
};

export default Exchange;