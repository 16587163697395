import React, { useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import liquiditypng from "../../../../assets/images/liquiditypng.png"
import pls from "../../../../assets/images/pls.png"
import que from "../../../../assets/images/floki.png";
import { P, SectionSmallHeading } from '../../../Styles/style'
import { Split, Swaper, SwapingButton, Tokenimg } from '../S1_Swap/style'
import unknown from "../../../../assets/images/unknown.png"
import { AccordionStyled, OutlinedBtn } from '../style'
import ImportPool from './importPool'
import AddLiuidity from './addLiquidity';

const Main = () => {
  const [PageState , setPageState] = useState("main")
  const [poolFound, setPoolFound] = useState(true)

  const handleFindBtn = () => {
    setPageState("import")
  }
  const handleAddBtn = () => {
    setPageState("add")
  }
  const handleRemoveBtn = () => {
    setPoolFound(false)
  }
  return (<>
  {
    PageState === "import" ? <ImportPool/> :
    PageState === "add" ? <AddLiuidity/> :
    (<>
    <div style={{ textAlign: "center" }}>
        <img src={liquiditypng}/>
        <SectionSmallHeading align="center" p="10px 0 20px">Your Liquidity</SectionSmallHeading>
    </div>
    {
      poolFound ? 
      <AccordionStyled sx={{border:"1px solid ##ffffff26"}}>
        <AccordionSummary
        // sx={{border:"1px solid ##ffffff26"}}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <img src={unknown} style={{width:"70px",height:"46px"}}/>
          <div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}>
          <P fs="14px" p="10px 0 0 10px" m="0" align="center"> Calc/cal</P>
          <P color="#ffffff42" fs="14px" p="5px 0 0 10px" m="0" align="center">0.000026</P>
          </div>
        </AccordionSummary>
        <AccordionDetails >
          <Split p="5px 0">
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Tokenimg src={pls} />
              <P fs="14px" p="10px 0 0 10px" m="0" align="center"> Pooled Cal:</P>
            </div>
            <P color="#ffffff42" fs="14px" p="5px 0 0 10px" m="0" align="center">0.00774121</P>
          </Split>
          <Split  p="5px 0">
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Tokenimg src={que} />
              <P fs="14px" p="10px 0 0 10px" m="0" align="center">Pooled Calc:</P>
            </div>
            <P color="#ffffff42" fs="14px" p="5px 0 0 10px" m="0" align="center">10000</P>
          </Split>
          <Split  p="5px 0">
              <P fs="14px" p="10px 0 0 10px" m="0" align="center">Share Of Pool</P>
            <P color="#ffffff42" fs="14px" p="5px 0 0 10px" m="0" align="center">0.02%</P>
          </Split>
          <SwapingButton p="10px 0" style={{margin:"10px 0"}} onClick={handleRemoveBtn}>Remove</SwapingButton>
          <OutlinedBtn  variant="outlined" onClick={handleAddBtn}>
          + Add Liquidity Instead
      </OutlinedBtn>
        </AccordionDetails>
      </AccordionStyled>
      : <></>
    }
    <Swaper>
      { poolFound ? <></> : <P color="#ffffff42" fs="14px" p="0" m="10px 0 20px" align="center"> No Liquidity Found </P>}
      <P color="#ffffff42" fs="14px" p="0" m="0" align="center">Don't See A Pool You Joined?</P>
      <OutlinedBtn p="5px 0" maxWidth="50%"  maxWidth1170="70%" maxWidth400="90%" variant="outlined" onClick={handleFindBtn}>
        Find Other Lp Tokens
      </OutlinedBtn>
    </Swaper>
    <SwapingButton p="10px 0" style={{margin:"20px 0 0"}} onClick={handleAddBtn}>+Add Liquidity</SwapingButton>
  </>)}
    </>
  )
}

export default Main
