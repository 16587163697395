import React, { useEffect, useState } from "react";
import AddIcon from "../../../../assets/images/AddIcon.png";

import Web3 from 'web3';
import { P, SectionSmallHeading } from "../../../Styles/style";
import { GridWrap, InpuFieldSelect, InputDIv, Inputoken, MaxButton, SelectedToken, Split, Swaper, SwapingBackButton, SwapingButton, Tokenimg, WrapDiv } from "../S1_Swap/style";
import { PLSAddress, FlokiTokenAddress, USDCAddress, USDTAddress, FlokiTokenABI, DAIAddress, RouterContractABI, RouterContractAddress, contractAbi, factoryContractAddress, factoryABI, LPtokenContractABI, tokenABI, } from "../../../../Abis&Ca";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, } from "@mui/material";
import pls from "../../../../assets/images/pls.png";
import floki from "../../../../assets/images/floki.png";
import inc from "../../../../assets/images/inc.png";
import timer from "../../../../assets/images/que.png";
import dai from "../../../../assets/images/dai.png";
import pulsexicon from "../../../../assets/images/pulsexicon.png";
import usdc from "../../../../assets/images/usdc.png";
import usdt from "../../../../assets/images/usdt.png";
import { AiOutlineDown } from "react-icons/ai";
import Main from "./main";
import AddAmount from "./AddAmount";
import styled from "@emotion/styled";
const AddLiuidity = (props) => {
  const [formattedFirstBalance, setFormattedFirstBalance] = useState("");
  const [formattedSecondBalance, setFormattedSecondBalance] = useState("");
  const [userBalance, setUserBalance] = useState(0);
  const [UserTokenBalance, setUserTokenBalance] = useState(0);

  const [balance, setBalance] = useState(0);
  const [useInputSlipage, setInputSlipage] = useState("10.0");
  const [useInputDeadline, setInputDeadline] = useState("20");

  const [Decimal, setDecimal] = useState("");
  const [isApproved, setIsApproved] = useState(false);
  const [isApproving, setIsApproving] = useState(false);
  const [gasPrice, setGasPrice] = useState('');
  const [InputTokenAddress, setInputTokenAddress] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [pairAddress, setPairAddress] = useState("0x20D7294E795B424AD8653168FCb3A791F4B44b21");
  const [PirceInUSD, setPirceInUSD] = useState('');
  const [PLSInUSD, setPLSInUSD] = useState('');
  const [PlsPrice, setPlsPrice] = useState('0');
  const [ShowPrice, setShowPrice] = useState('0');
  const [TokenName, setTokenName] = useState('');
  const [balanceToken, setBalanceToken] = useState(0);
  const [ExistToken, setExistToken] = useState("");

  const [pooledWPLSAddress, setPooledWPLSAddress] = useState('');
  const [pooledTokenAddress, setPooledTokenAddress] = useState("");
  const [importedTokenAddress, setImportedTokenAddress] = useState("");
  const [OpenInfo, setOpenInfo] = useState(false);
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);


  const [isConnected, setIsConnected] = useState(false);
  const [error, setError] = useState('');

  const [UserAccount, setUserAccount] = useState("");
  const [BottomAddress, setBottomAddress] = useState(FlokiTokenAddress);
  const [UpperAddress, setUpperAddress] = useState(PLSAddress);
  const [PlsToFlokiAmount, setPlsToFlokiAmount] = useState('');
  const [FlokiToPlsAmount, setFlokiToPlsAmount] = useState('');

  const [getInputBTNDisabled, setInputBTNDisabled] = useState(false);
  const [PageState, setPageState] = useState("import");

  const handleFindBtn = () => {
    setPageState("main");
  };
  const handleAmuntBtn = () => {
    setPageState("AddAmount");
  };
  const [selectedOption1, setSelectedOption1] = useState({
    title: "Cal",
    icon: pls,
    address: PLSAddress,
  });
  const [selectedOption2, setSelectedOption2] = useState({
    title: "Calc",
    icon: floki,
    address: FlokiTokenAddress,
  });

  const [toggle, setToggle] = useState(true);
  const [options, setOptions] = useState(() => {
    // Retrieve options from localStorage, or use a default value if not found
    const storedOptions = localStorage.getItem("importedTokens");
    const parsedOptions = storedOptions
      ? JSON.parse(storedOptions)
      : [
        // {
        //   title: "FLOKI",
        //   icon: floki,
        //   address: "0x60C89f5eC551350235388b33d10643d46CaBE907",
        //   pairaddress: "0xBC639079Ec68c583294cecD52a9dCD1561B70340",
        //   disabled:
        //     selectedOption1.title === "FLOKI" ||
        //     selectedOption2.title === "FLOKI",
        // },
        // {
        //   title: "PLSX",
        //   icon: pulsexicon,
        //   pairaddress: "0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9",
        //   address: "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab",
        //   disabled:
        //     selectedOption1.title === "PLSX" ||
        //     selectedOption2.title === "PLSX",
        // },
        // {
        //   title: "INC",
        //   icon: inc,
        //   pairaddress: "0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA",
        //   address: "0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d",
        //   disabled:
        //     selectedOption1.title === "INC" ||
        //     selectedOption2.title === "INC",
        // },
        // {
        //   title: "DAI",
        //   icon: dai,
        //   pairaddress: "0xE56043671df55dE5CDf8459710433C10324DE0aE",
        //   address: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
        //   disabled:
        //     selectedOption1.title === "DAI" ||
        //     selectedOption2.title === "DAI",
        // },
        // {
        //   title: "Eth",
        //   icon: pls,
        //   address: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
        //   disabled:
        //     selectedOption1.title === "PLS" ||
        //     selectedOption2.title === "PLS",
        // },
        // {
        //   title: "USDC",
        //   icon: usdc,
        //   pairaddress: "0x6753560538ECa67617A9Ce605178F788bE7E524E",
        //   address: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
        //   disabled:
        //     selectedOption1.title === "USDC" ||
        //     selectedOption2.title === "USDC",
        // },
        // {
        //   title: "USDT",
        //   icon: usdt,
        //   address: "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f",
        //   disabled:
        //     selectedOption1.title === "USDT" ||
        //     selectedOption2.title === "USDT",
        // },
        // {
        //   title: "FLUFFY",
        //   icon: "",
        //   pairaddress: "0xB8331Abf8aCf6e9D33C69e34fdA213B7b97e9Bf2",
        //   address: "0xA9D4230b4899e6aaC0D84e540941B3832ABA3ba0",
        //   disabled:
        //     selectedOption1.title === "FLUFFY" ||
        //     selectedOption2.title === "FLUFFY",
        // },
        // {
        //   title: "RICH",
        //   icon: "",
        //   pairaddress: "0xCEc92bb8B196d3D797bdBB60c1029825C0CD478a",
        //   address: "0x5a266ae80608148F2351bf29903bD39B9d0D5Ced",
        //   disabled:
        //     selectedOption1.title === "RICH" ||
        //     selectedOption2.title === "RICH",
        // },
      ];
    return parsedOptions.map((option) => ({
      ...option,
    }));
  });

  const activeValue = toggle ? "UPPERFIELD" : "BOTTOMFIELD";

  const handleFirstInputChange = (e) => {
    const value = e.target.value;
    setFormattedFirstBalance(value);
  };
  const handleSecondInputChange = (e) => {
    const value = e.target.value;
    setFormattedSecondBalance(value);
  };
  const handleDialogOpen1 = () => setDialogOpen1(true);
  const handleDialogOpen2 = () => setDialogOpen2(true);
  const handleOptionSelect2 = (option) => {
    setSelectedOption2(option);
    setSelectedOption1((prevOption) =>
      prevOption.title === option.title
        ? { title: "", icon: null, address: "" }
        : prevOption
    );
    setDialogOpen2(false);
    setBottomAddress(option.address);
    setPairAddress(option.pairaddress);
  };

  const handleOptionSelect1 = (option) => {
    setSelectedOption1(option);
    setSelectedOption2((prevOption) =>
      prevOption.title === option.title
        ? { title: "", icon: null, address: "" }
        : prevOption
    );
    setDialogOpen1(false);
    setUpperAddress(option.address);
    setPairAddress(option.pairaddress);
  };
  const handleDialogClose1 = () => setDialogOpen1(false);
  const handleDialogClose2 = () => setDialogOpen2(false);

  const firstSelectedTokenProps = {
    onClick: handleDialogOpen1,
    imgSrc: selectedOption1.icon,
    tokenValue: selectedOption1.title,
  };
  const secondSelectedTokenProps = {
    onClick: handleDialogOpen2,
    imgSrc: selectedOption2.icon,
    tokenValue: selectedOption2.title,
  };
  const firstInputProps = {
    type: "number",
    placeholder: "0.00",
    value: formattedFirstBalance,
    onChange: handleFirstInputChange,
  };
  const secondInputProps = {
    type: "number",
    placeholder: "0.00",
    value: formattedSecondBalance,
    onChange: handleSecondInputChange,
  };

  const fetchTokenInfo = async () => {
    try {
      if (window.ethereum) {
        await window.ethereum.enable();
      }
      const web3 = new Web3(window.ethereum);
      const tokenContract = new web3.eth.Contract(tokenABI, InputTokenAddress);
      const symbol = await tokenContract.methods.symbol().call();
      const Name = await tokenContract.methods.name().call();
      if (!symbol || !Name) {
        console.log('Token does not exist');
        return;
      }
      setTokenName(Name);
      setTokenSymbol(symbol);
      const factoryContract = new web3.eth.Contract(factoryABI, factoryContractAddress);
      const pair = await factoryContract.methods.getPair(InputTokenAddress, UpperAddress).call();
      setPairAddress(pair);
      setImportedTokenAddress(InputTokenAddress);
    } catch (error) {
      console.error(error);
    }
  };
  fetchTokenInfo();
  const importToken = (address) => {
    const existingToken = options.find((option) => option.address === address);
    if (existingToken) {
      setExistToken(existingToken.title);
      setOpenInfo(true);
      console.log("Token already exists:", existingToken.title);
      return;
    }
    const newToken = { title: tokenSymbol, icon: null, pairaddress: pairAddress, address, disabled: false, };
    const updatedOptions = [...options, newToken];
    setOptions(updatedOptions);
    localStorage.setItem("importedTokens", JSON.stringify(updatedOptions));
    setBottomAddress(address);
    setTimeout(() => {
      setInputTokenAddress("");
      setTokenSymbol("");
      setImportedTokenAddress("");
    }, 5000);
  };
  useEffect(() => {
    console.log("UserTokenBalance",UserTokenBalance);
    console.log("formattedSecondBalance",formattedSecondBalance);
  // if (formattedSecondBalance >= UserTokenBalance){
  //   setInputBTNDisabled(true);
  // }else{
    
  //   setInputBTNDisabled(false);
  // }
    return () => {
      localStorage.removeItem('importedTokens');
    };
  }, [formattedSecondBalance,UserTokenBalance ,getInputBTNDisabled]);
  useEffect(() => {
    if (selectedOption1.address === PLSAddress) {
      const price = PLSInUSD * formattedFirstBalance;
      const formattedPrice = price.toFixed(10);
      setPlsPrice(formattedPrice);
    } else if (selectedOption1.address === USDCAddress || selectedOption1.address === DAIAddress || selectedOption1.address === USDTAddress) {
      setPlsPrice(formattedFirstBalance);
    } else {
      const price = PirceInUSD * formattedFirstBalance;
      const formattedPrice = price.toFixed(10);
      setPlsPrice(formattedPrice);
    }
    if (selectedOption2.address === USDCAddress || selectedOption2.address === DAIAddress || selectedOption2.address === USDTAddress) {
      setShowPrice(formattedSecondBalance);
    } else if (selectedOption2.address === PLSAddress) {
      const price = PLSInUSD * formattedSecondBalance;
      const formattedPrice = price.toFixed(10);
      setPlsPrice(formattedPrice);
    } else {
      // console.log("PirceInUSD", PirceInUSD);
      const price = PirceInUSD * formattedSecondBalance;
      const formattedPrice = price.toFixed(10);
      // console.log("formattedPrice", formattedPrice);
      setShowPrice(formattedPrice);
    }
    const web3 = new Web3(window.ethereum);
    const fetchUserNativeBalance = async () => {
      if (web3 && UserAccount) {
        try {
          if (selectedOption1.address === PLSAddress) {
            const balance = await web3.eth.getBalance(UserAccount);

            const formattedBalance = web3.utils.fromWei(balance, 'ether');
            const balanceNumber = Number(formattedBalance);
            const formattedBalances = balanceNumber.toFixed(3);
            const truncatedBalance = formattedBalances.substring(0, 22);
            setUserBalance(truncatedBalance);
            setBalance(truncatedBalance);
          } else {
            if (selectedOption1.address === USDCAddress || selectedOption1.address === USDTAddress) {
              const web3 = new Web3(window.ethereum);
              const tokenContract = new web3.eth.Contract(FlokiTokenABI, UpperAddress);
              const tokenBalance = await tokenContract.methods.balanceOf(UserAccount).call();
              setUserBalance(tokenBalance / 1e6);
              setBalance(tokenBalance / 1e6);
            } else {
              const tokenContract = new web3.eth.Contract(FlokiTokenABI, UpperAddress);
              const tokenBalance = await tokenContract.methods.balanceOf(UserAccount).call();
              const balanceNumber = Number(tokenBalance / 1e18);
              const formattedBalance = balanceNumber.toFixed(3);
              const truncatedBalance = formattedBalance.substring(0, 22);
              setUserBalance(truncatedBalance);
              setBalance(truncatedBalance);
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    const fetchBalance = async () => {
      try {
        if (selectedOption2.address === PLSAddress) {
          const balance = await web3.eth.getBalance(UserAccount);
          const formattedBalance = web3.utils.fromWei(balance, 'ether');
          const balanceNumber = Number(formattedBalance);
          const formattedBalances = balanceNumber.toFixed(3);
          const truncatedBalance = formattedBalances.substring(0, 22);
          setBalanceToken(formattedBalance);
          setUserTokenBalance(truncatedBalance);
        }
        else {
          if (selectedOption2.address === USDCAddress || selectedOption2.address === USDTAddress) {
            const web3 = new Web3(window.ethereum);
            const tokenContract = new web3.eth.Contract(FlokiTokenABI, BottomAddress);
            const tokenBalance = await tokenContract.methods.balanceOf(UserAccount).call();
            setBalanceToken(tokenBalance / 1e6);
            setUserTokenBalance(tokenBalance / 1e6);
          } else {
            const web3 = new Web3(window.ethereum);
            // console.log("BottomAddress",BottomAddress);
            const tokenContract = new web3.eth.Contract(FlokiTokenABI, BottomAddress);
            const DecimalValue = await tokenContract.methods.decimals().call();
            setDecimal(DecimalValue);
            const tokenBalance = await tokenContract.methods.balanceOf(UserAccount).call();
            // console.log("tokenBalance",tokenBalance / (10 ** Decimal));
            // setBalanceToken(tokenBalance / 1e18);
            setBalanceToken(tokenBalance / (10 ** Decimal));
            const balanceNumber = Number(balanceToken);
            const formattedBalance = balanceNumber.toFixed(3);
            const truncatedBalance = formattedBalance.substring(0, 22);
            setUserTokenBalance(truncatedBalance);
          }
        }
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };
    fetchUserNativeBalance();
    fetchBalance();
    const fetchGasPrice = async () => {
      try {
        const web3 = new Web3('https://goerli.infura.io/v3/5757d08e76ea4597ba83a4e6b955f01f'); // Replace with the Pulse chain RPC endpoint
        const gasPriceWei = await web3.eth.getGasPrice();
        const gasPriceGwei = web3.utils.fromWei(gasPriceWei, 'gwei');
        setGasPrice(gasPriceGwei);
      } catch (error) {
        console.error('Error fetching gas price:', error);
      }
    };

    fetchGasPrice();

    const handleConvert = async () => {
      const web3 = new Web3('https://goerli.infura.io/v3/5757d08e76ea4597ba83a4e6b955f01f'); // Replace with the Pulse chain RPC endpoint

      const inputAmountWei = web3.utils.toWei("1");
      const routerContract = new web3.eth.Contract(RouterContractABI, RouterContractAddress);

      const plsToFlokiAmounts = routerContract.methods.getAmountsOut(inputAmountWei, [UpperAddress, BottomAddress]).call();
      const flokiToPlsAmounts = routerContract.methods.getAmountsOut(inputAmountWei, [BottomAddress, UpperAddress]).call();

      Promise.all([plsToFlokiAmounts, flokiToPlsAmounts])
        .then(([plsToFlokiAmounts, flokiToPlsAmounts]) => {
          const plsToFlokiOutputAmount = plsToFlokiAmounts[1];
          const flokiToPlsOutputAmount = flokiToPlsAmounts[1];

          const plsToFlokiHumanReadable = plsToFlokiOutputAmount / (10 ** Decimal);
          const flokiToPlsHumanReadable = flokiToPlsOutputAmount / (10 ** Decimal);

          const plsToFlokiNumber = Number(plsToFlokiHumanReadable);
          const flokiToPlsNumber = Number(flokiToPlsHumanReadable);

          const plsToFlokiFormatted = plsToFlokiNumber.toFixed(5);
          const flokiToPlsFormatted = flokiToPlsNumber.toFixed(5);

          const plsToFlokiTruncated = plsToFlokiFormatted.substring(0, 22);
          const flokiToPlsTruncated = flokiToPlsFormatted.substring(0, 22);

          setPlsToFlokiAmount(plsToFlokiTruncated);
          setFlokiToPlsAmount(flokiToPlsTruncated);
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };
    handleConvert();
  }, [formattedSecondBalance, PLSInUSD, PlsPrice, PirceInUSD, selectedOption1, selectedOption2, formattedFirstBalance, UserAccount, BottomAddress, balance, userBalance, balanceToken, UserTokenBalance,]);

  const fetchPLSPriceInUSD = async () => {
    try {
      const web3 = new Web3('https://goerli.infura.io/v3/5757d08e76ea4597ba83a4e6b955f01f');
      // console.log("pairAddress", pairAddress);
      const contract = new web3.eth.Contract(contractAbi, pairAddress);
      const reserves = await contract.methods.getReserves().call();
      // console.log("reserves", reserves);
      contract.methods.getReserves().call()
        .then(reserves => {
          const token0 = contract.methods.token0().call();
          const token1 = contract.methods.token1().call();
          return Promise.all([token0, token1])
            .then(tokens => {
              setPooledWPLSAddress(tokens[0]);
              setPooledTokenAddress(tokens[1]);
              setError('');
            });
        })
        .catch(error => {
          setError(error.message);
        });

      let PooledNativePLS, PooledTokens;
      const FirstPooled = parseFloat(web3.utils.fromWei(reserves[0], 'ether'));
      const SecondPooled = parseFloat(web3.utils.fromWei(reserves[1], 'ether'));

      if (FirstPooled < SecondPooled) {
        PooledNativePLS = web3.utils.fromWei(reserves[0], 'ether');
        PooledTokens = web3.utils.fromWei(reserves[1], 'ether');
      } else {
        PooledNativePLS = web3.utils.fromWei(reserves[1], 'ether');
        PooledTokens = web3.utils.fromWei(reserves[0], 'ether');
      }

      // console.log("PooledNativePLS", PooledNativePLS);
      // console.log("PooledTokens", PooledTokens);

      const priceInPLS = PooledNativePLS / PooledTokens;

      const exchangeRatePLStoUSD = await fetchExchangeRatePLStoUSD(); // Replace with the function to fetch the exchange rate
      setPLSInUSD(exchangeRatePLStoUSD);
      const priceUSD = priceInPLS * exchangeRatePLStoUSD;
      const decimalNumber = priceUSD.toFixed(10);
      setPirceInUSD(decimalNumber);
    } catch (error) {
      console.error('Error fetching PLS price:', error);
    }
  };
  const formatBalance = () => {
    const balanceNumber = Number(activeValue === "UPPERFIELD" ? balance : balanceToken);
    const formattedBalance = balanceNumber.toFixed(3);
    const truncatedBalance = formattedBalance.substring(0, 22);

    if (!activeValue !== "UPPERFIELD") {
      if (selectedOption2.address === USDCAddress || selectedOption2.address === DAIAddress || selectedOption2.address === USDTAddress) {
        setShowPrice(formattedSecondBalance);
      } else {
        const price = PirceInUSD * formattedSecondBalance;
        const formattedPrice = price.toFixed(10);
        setShowPrice(formattedPrice);
      }
    }
    if (activeValue === "UPPERFIELD") {
      setFormattedFirstBalance(truncatedBalance);
      if (selectedOption1.address === PLSAddress || selectedOption2.address === PLSAddress) {
        const price = PLSInUSD * formattedFirstBalance;
        const formattedPrice = price.toFixed(10);
        setPlsPrice(formattedPrice);
      } else if (selectedOption1.address === USDCAddress || selectedOption1.address === DAIAddress || selectedOption1.address === USDTAddress) {
        setPlsPrice(formattedFirstBalance);
      } else {
        const price = PirceInUSD * formattedFirstBalance;
        const formattedPrice = price.toFixed(10);
        setPlsPrice(formattedPrice);
      }
    } else {
      setFormattedSecondBalance(truncatedBalance);
    }
  };
  const handleCalculation = () => {

    const web3 = new Web3('https://goerli.infura.io/v3/5757d08e76ea4597ba83a4e6b955f01f'); // Replace with the Pulse chain RPC endpoint
    let outputTokenAddress = toggle ? UpperAddress : BottomAddress;
    let inputTokenAddress = toggle ? BottomAddress : UpperAddress;
    if (activeValue === "UPPERFIELD") {
      if (formattedFirstBalance === '' || isNaN(Number(formattedFirstBalance))) {
        return;
      }
    } else if (activeValue === "BOTTOMFIELD") {
      if (formattedSecondBalance === '' || isNaN(Number(formattedSecondBalance))) {
        return;
      }
    }
    const inputAmountWei = web3.utils.toWei(toggle ? formattedFirstBalance : formattedSecondBalance);
    const routerContract = new web3.eth.Contract(RouterContractABI, RouterContractAddress);

    routerContract.methods.getAmountsOut(inputAmountWei, [outputTokenAddress, inputTokenAddress])
      .call()
      .then(amounts => {
        if (selectedOption2.address === USDCAddress || selectedOption2.address === USDTAddress) {
          const estimatedAmountOutWei = amounts[1];

          // const truncatedBalance = (estimatedAmountOutWei / (10 ** Decimal)).toString();
          const truncatedBalance = (estimatedAmountOutWei / 1e6).toString();
          const estimatedAmountOutHumanReadable = web3.utils.fromWei(estimatedAmountOutWei);
          const balanceNumber = Number(estimatedAmountOutHumanReadable);
          const formattedBalance = balanceNumber.toFixed(5);
          if (activeValue == "UPPERFIELD") {
            setFormattedSecondBalance(truncatedBalance);
          } else {
            setFormattedFirstBalance(formattedBalance);
          }
        } else {
          const estimatedAmountOutWei = amounts[1];
          const estimatedAmountOutHumanReadable = (estimatedAmountOutWei / (10 ** Decimal));
          // const estimatedAmountOutHumanReadable = (estimatedAmountOutWei / 1e18);
          const balanceNumber = Number(estimatedAmountOutHumanReadable);
          const formattedBalance = balanceNumber.toFixed(5);
          const truncatedBalance = formattedBalance.substring(0, 22);
          // console.log("truncatedBalance",truncatedBalance / 1e18);
          if (activeValue == "UPPERFIELD") {
            setFormattedSecondBalance(truncatedBalance);
          } else {
            setFormattedFirstBalance(truncatedBalance);
          }
        }
      })
      .catch(error => {
        console.error('Error:', error);
      });
  };

  const fetchExchangeRatePLStoUSD = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=pulsechain&vs_currencies=usd');
      const data = await response.json();
      const exchangeRate = data.pulsechain.usd;
      localStorage.setItem('lastExchangeRatePLStoUSD', exchangeRate);
      return exchangeRate;
    } catch (error) {
      const lastExchangeRate = localStorage.getItem('lastExchangeRatePLStoUSD');
      if (lastExchangeRate) {
        return parseFloat(lastExchangeRate); // Convert the stored value to a number
      } else {
        console.error('Error fetching exchange rate:', error);
        return null;
      }
    }
  };
  const checkWalletConnection = async () => {
    if (window.ethereum) {
      try {

        const web3 = new Web3(window.ethereum);
        // Set up the event listener for account changes
        window.ethereum.on('accountsChanged', (accounts) => {
          setUserAccount(accounts[0]);
          console.log("accounts[0]", accounts[0]);
          setIsConnected(accounts.length > 0);
        });

        const accounts = await web3.eth.getAccounts();
        setUserAccount(accounts[0]);
        setIsConnected(accounts.length > 0);
      } catch (error) {
        console.log('Error occurred while checking wallet connection:', error);
      }
    }
  };


  checkWalletConnection();
  fetchPLSPriceInUSD();

  handleCalculation();

  const handleAddLiquidityETH = async () => {
    const web3 = new Web3(window.ethereum);
    console.log("formattedFirstBalance", formattedFirstBalance);
    console.log("formattedSecondBalance", formattedSecondBalance);
    const routerContract = new web3.eth.Contract(RouterContractABI, RouterContractAddress);
    const deadline = Math.floor(Date.now() / 1000) + 600; // Set the deadline to 10 minutes from now

    // Define the input parameters
    const token = FlokiTokenAddress; // Address of the token you want to add liquidity with ETH
    const amountTokenDesired = web3.utils.toWei(formattedSecondBalance); // Desired amount of token you want to add
    const amountTokenMin = web3.utils.toWei('1'); // Minimum acceptable amount of token
    const amountETHMin = web3.utils.toWei(formattedFirstBalance); // Minimum acceptable amount of ETH
    const to = UserAccount; // Address where the liquidity tokens will be sent
    const walletAddress = UserAccount; // Replace with your actual wallet address

    try {
      const result = await routerContract.methods
        .addLiquidityETH(BottomAddress, amountTokenDesired, '0', '0', to, deadline)
        .send({ from: walletAddress, value: amountETHMin, gasLimit: 1000000 });
        console.log('0xcdcEea107375ECDC28a334274E75e67f09d02f50',amountTokenDesired,amountTokenMin,amountETHMin,to,deadline)

      console.log('Transaction successful:', result);
      // Handle the success response or update the state as needed
    } catch (error) {
      console.log('0xcdcEea107375ECDC28a334274E75e67f09d02f50',amountTokenDesired,amountTokenMin,amountETHMin,to,deadline)
      console.error('ErrorhandleAddLiquidityETH:', error);
      // Handle the error or display an error message
    }
  };


  const handleCreatePair = async () => {
    const web3 = new Web3(window.ethereum);
    console.log("formattedFirstBalance", formattedFirstBalance);
    console.log("formattedSecondBalance", formattedSecondBalance);
    const routerContract = new web3.eth.Contract( factoryABI , factoryContractAddress);
    const deadline = Math.floor(Date.now() / 1000) + 600; // Set the deadline to 10 minutes from now

    // Define the input parameters
    const token = '0xbFaa6ef531535fe37C57767915dbD530Ee6B050F'; // Address of the token you want to add liquidity with ETH
    const amountTokenDesired = web3.utils.toWei(formattedSecondBalance); // Desired amount of token you want to add
    const amountTokenMin = web3.utils.toWei('1'); // Minimum acceptable amount of token
    const amountETHMin = web3.utils.toWei(formattedFirstBalance); // Minimum acceptable amount of ETH
    const to = UserAccount; // Address where the liquidity tokens will be sent
    const walletAddress = UserAccount; // Replace with your actual wallet address

    try {
      const result = await routerContract.methods
      // .createPair('0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6','0xcdcEea107375ECDC28a334274E75e67f09d02f50')
      .createPair(UpperAddress,BottomAddress)

        .send({ from: walletAddress});
        console.log('0x73abD8F159a7F146fDC2ce5017BcEC30E49462Fc',amountTokenDesired,amountTokenMin,amountETHMin,to,deadline)

      console.log('Transaction successful:', result);
      // Handle the success response or update the state as needed
    } catch (error) {
      console.log('0x73abD8F159a7F146fDC2ce5017BcEC30E49462Fc',amountTokenDesired,amountTokenMin,amountETHMin,to,deadline)
      console.error('ErrorhandleAddLiquidityETH:', error);
      // Handle the error or display an error message
    }
  };


  const handleApprove = async () => {
    setIsApproving(true);
    const web3 = new Web3(window.ethereum);
    console.log("formattedFirstBalance", formattedFirstBalance);
    console.log("formattedSecondBalance", formattedSecondBalance);
    const routerContract = new web3.eth.Contract( FlokiTokenABI,BottomAddress );
    
    console.log("data",FlokiTokenAddress);

    const deadline = Math.floor(Date.now() / 1000) + 600; // Set the deadline to 10 minutes from now
    console.log("test",typeof(routerContract));
    // Define the input parameters
    const token = BottomAddress; // Address of the token you want to add liquidity with ETH
    const amountTokenDesired = web3.utils.toWei(formattedSecondBalance); // Desired amount of token you want to add
    const amountTokenMin = web3.utils.toWei('1'); // Minimum acceptable amount of token
    const amountETHMin = web3.utils.toWei(formattedFirstBalance); // Minimum acceptable amount of ETH
    const to = UserAccount; // Address where the liquidity tokens will be sent
    const walletAddress = UserAccount; // Replace with your actual wallet address

    try {
      console.log("",typeof('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D'));
      const result = await routerContract.methods
      // const amountApprove = formattedSecondBalance*1000000000000000000
      // console.log('get',amountApprove)
      // .createPair('0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6','0xcdcEea107375ECDC28a334274E75e67f09d02f50')
      .approve('0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',formattedSecondBalance)

        .send({ from: walletAddress,});
        setIsApproved(true);
        console.log('0x73abD8F159a7F146fDC2ce5017BcEC30E49462Fc',amountTokenDesired,amountTokenMin,amountETHMin,to,deadline)

      console.log('Transaction successful:', result);
      // Handle the success response or update the state as needed
    } catch (error) {
      console.log('0x73abD8F159a7F146fDC2ce5017BcEC30E49462Fc',amountTokenDesired,amountTokenMin,amountETHMin,to,deadline)
      console.error('ErrorhandleAddLiquidityETH:', error);
      // Handle the error or display an error message
    }
    setIsApproving(false);
  };







  const web3 = new Web3(window.ethereum);
  const lpTokenContract = new web3.eth.Contract(LPtokenContractABI, "0xf8077D17A58731b11de439e791Cd01d1804e1d1F");
  const walletAddress = UserAccount; // Replace with your actual wallet address
  const checkLPTokenBalance = async () => {
    try {
      const balance = await lpTokenContract.methods.balanceOf(walletAddress).call();
      console.log('LP Token balance:', balance);
      // Handle the LP token balance or update the state as needed
    } catch (error) {
      console.error('Error checking LP token balance:', error);
      // Handle the error or display an error message
    }
  };

  checkLPTokenBalance();

  return (
    <>
      {PageState === "main" ? (
        <Main />
      ) : PageState === "AddAmount" ? (
        <AddAmount />
      ) : (
        <>
          <Dialog
            PaperProps={{
              style: {
                minWidth: "400px",
                minHeight: "400px",
                background:
                  "linear-gradient(0deg, rgb(0 0 0 / 89%) 40%, rgb(0, 0, 0) 78%)",
                border: "1px solid #ffffff2b",
              },
            }}
            open={dialogOpen1}
            onClose={handleDialogClose1}
          >
            <Split>
              <DialogContent style={{ color: "#fff" }}>
                Select Token
              </DialogContent>
              <DialogActions>
                <Button style={{ color: "#fff" }} onClick={handleDialogClose1}>
                  X
                </Button>
              </DialogActions>
            </Split>
            <DialogActions>
              <Inputoken
                type="text"
                placeholder="Enter token address"
                value={InputTokenAddress}
                onChange={(e) => setInputTokenAddress(e.target.value)}
              />
            </DialogActions>
            <Split p="0 20px 0 0">
              {importedTokenAddress && (
                <>
                  <P p="0 20px " m="0">  Token : {tokenSymbol} </P>
                  <SwapingButton p="7px 0" maxWidth="30%" onClick={() => importToken(importedTokenAddress)} >  Import </SwapingButton>
                </>
              )}
            </Split>
            <DialogContent>
              {options.map((option) => (
                <WrapDiv>
                  <Tokenimg
                    // style={{ opacity: option.disabled ? 0.5 : 1 }}
                    src={option.icon || timer}
                    alt={option.title}
                    className="dialog-icon"
                  />
                  <Button
                    key={option.title}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      color: "#fff",
                      // opacity: option.disabled ? 0.5 : 1, // Apply opacity to disabled options
                    }}
                    onClick={() => handleOptionSelect1(option)}
                  // disabled={option.disabled}
                  >
                    &nbsp;&nbsp;
                    {option.title}
                  </Button>
                </WrapDiv>
              ))}
            </DialogContent>
          </Dialog>

          <Dialog
            PaperProps={{
              style: {
                minWidth: "400px",
                minHeight: "400px",
                background:
                  "linear-gradient(0deg, rgb(0 0 0 / 89%) 40%, rgb(0, 0, 0) 78%)",
                border: "1px solid #ffffff2b",
              },
            }}
            open={dialogOpen2}
            onClose={handleDialogClose2}
          >
            <Split>
              <DialogTitle style={{ color: "#fff" }}>Select Token</DialogTitle>
              <DialogActions>
                <Button style={{ color: "#fff" }} onClick={handleDialogClose2}>
                  X
                </Button>
              </DialogActions>
            </Split>
            <DialogActions>
              <Inputoken
                type="text"
                placeholder="Enter token address"
                value={InputTokenAddress}
                onChange={(e) => setInputTokenAddress(e.target.value)}
              />
            </DialogActions>
            <Split p="0 20px 0 0">
              {importedTokenAddress && (
                <>
                  <P p="0 20px " m="0">
                    Token: {tokenSymbol}
                  </P>
                  <SwapingButton
                    p="7px 0"
                    maxWidth="30%"
                    onClick={() => importToken(importedTokenAddress)}
                  >
                    Import
                  </SwapingButton>
                </>
              )}
            </Split>
            <DialogContent>
              {options.map((option) => (
                <WrapDiv>
                  <Tokenimg
                    // style={{ opacity: option.disabled ? 0.5 : 1 }}
                    src={option.icon || timer}
                    alt={option.title}
                    className="dialog-icon"
                  />
                  <Button
                    key={option.title}
                    style={{
                      fontSize: "18px",
                      display: "flex",
                      flexDirection: "column",
                      color: "#fff",
                      // opacity: option.disabled ? 0.5 : 1, // Apply opacity to disabled options
                    }}
                    onClick={() => handleOptionSelect2(option)}
                  // disabled={option.disabled}
                  >
                    &nbsp;&nbsp;
                    {option.title}
                  </Button>
                </WrapDiv>
              ))}
            </DialogContent>
          </Dialog>
          <div style={{ textAlign: "center" }}>
            <img src={AddIcon} />
            <SectionSmallHeading align="center" p="10px 0 20px" style={{ position: "relative" }}>
              Add Liquidity
              <SwapingBackButton
                style={{ top: "27%" }}
                onClick={handleFindBtn}
              >
                {'< '} back
              </SwapingBackButton>
            </SectionSmallHeading>
          </div>
          <Swaper p="10px 20px">
            <div style={{ textAlign: "right" }}>

            </div>
            <InputDIv>
              <SelectedToken bgc="transparent"{...firstSelectedTokenProps}>
                <Tokenimg src={firstSelectedTokenProps.imgSrc || timer} />
                &nbsp;&nbsp;
                {firstSelectedTokenProps.tokenValue}
                {/* &nbsp;&nbsp; */}
                <AiOutlineDown style={{ margin: "0 4px",fontSize:"45px", color: "#fff" }} />
              </SelectedToken>
              <InpuFieldSelect {...firstInputProps} />
              <MaxButton onClick={formatBalance}>Max</MaxButton>
            </InputDIv>
            {/* {activeValue === "UPPERFIELD" ? ( */}
            <Split>
              <P fs="14px" color="#ffffff42" p="30px 0 0" m="0">

                Balance: {userBalance}&nbsp;{firstSelectedTokenProps.tokenValue}
              </P>
            </Split>
            {/* ) : ( */}
            {/* <Split>
                <P fs="14px" color="#ffffff42" p="30px 0 0" m="0">

                  Balance: {UserTokenBalance}&nbsp;{firstSelectedTokenProps.tokenValue}
                </P>
              </Split> */}
            {/* )} */}
          </Swaper>
          <Split>
            <P color="#ffffff" fs="18px" p="0 50%" m="15px 0 15px" align="center">

              +
            </P>
          </Split>
          <Swaper p="10px 20px">
            <InputDIv>
              <SelectedToken bgc="transparent"{...secondSelectedTokenProps}>
                <Tokenimg src={secondSelectedTokenProps.imgSrc || timer} />
                &nbsp;&nbsp;
                {secondSelectedTokenProps.tokenValue}
                <AiOutlineDown style={{ margin: "0 4px", fontSize:"45px", color: "#fff" }} />
              </SelectedToken>
              <InpuFieldSelect {...secondInputProps} />
            </InputDIv>
            {/* {activeValue === "BOTTOMFIELD" ? ( */}
            {/* <Split>
                <P fs="14px" color="#ffffff42" p="30px 0 0" m="0">
                  Balance: {userBalance}&nbsp;{secondSelectedTokenProps.tokenValue}
                </P> */}
            {/* </Split> */}
            {/* ) : ( */}
            <Split>
              <P fs="14px" color="#ffffff42" p="30px 0 0" m="0">

                Balance: {UserTokenBalance}&nbsp;{secondSelectedTokenProps.tokenValue}
              </P>
            </Split>
            {/* )}/ */}
          </Swaper>
          <Split>
            <P color="#ffffff" fs="14px" p="0" m="20px 0 10px" >

              Prices And Pool Share
            </P>
          </Split>
          <Swaper >
            <GridWrap contianer >
              <Grid item xs={4}>
                <P color="#ffffff42" fs="14px" p="0" m="0px 0 10px" align="center">

                  {FlokiToPlsAmount}
                </P>
                <P color="#ffffff42" fs="14px" p="0" m="0px 0 10px" align="center">

                  {firstSelectedTokenProps.tokenValue} Per {secondSelectedTokenProps.tokenValue}
                </P>
              </Grid>
              <Grid item xs={4}>
                <P color="#ffffff42" fs="14px" p="0" m="0px 0 10px" align="center">

                  {PlsToFlokiAmount}

                </P>
                <P color="#ffffff42" fs="14px" p="0" m="0px 0 10px" align="center">

                  {secondSelectedTokenProps.tokenValue} Per {firstSelectedTokenProps.tokenValue}
                </P>
              </Grid>
              <Grid item xs={4}>
                <P color="#ffffff42" fs="14px" p="0" m="0px 0 10px" align="center">
                  {'<' + '0.01%'}

                </P>
                <P color="#ffffff42" fs="14px" p="0" m="0px 0 10px" align="center">

                  Share Of Pool
                </P>
              </Grid>

            </GridWrap>
          </Swaper>


        
          <div>

            {formattedFirstBalance < userBalance && formattedSecondBalance < UserTokenBalance ? (
              <div>
                <SwapingButton p="10px 0" style={{ margin: "20px 0 0" }}>
                  Enter Amount
                </SwapingButton>
              </div>
            ) : (
              <div>

                {!isApproved && (
                  <SwapingButton onClick={handleApprove} disabled={isApproving}>
                    {isApproving ? 'Approving...' : 'Approve'}
                  </SwapingButton>
                )}

                {formattedFirstBalance > userBalance && (
                  <div>
                    <SwapingButton p="10px 0" bgc="grey" style={{ margin: "20px 0 0" }}>
                      Insufficient balance
                    </SwapingButton>
                  </div>
                )}
                {formattedSecondBalance < UserTokenBalance && (
                  <div>
                    <SwapingButton
                      disabled={getInputBTNDisabled ? true : false} bgc="grey" p="10px 0" style={{ margin: "20px 0 0" }}>
                      Insufficient balance
                    </SwapingButton>
                  </div>
                )}
                {formattedFirstBalance <= userBalance && formattedSecondBalance >= UserTokenBalance && (
                  <div>
                    <SwapingButton p="10px 0" style={{ margin: "20px 0 0" }} onClick={handleCreatePair}   >Create Pair</SwapingButton>
                    <SwapingButton disabled={getInputBTNDisabled ? true : false} p="10px 0" style={{ margin: "20px 0 0" }} onClick={handleAddLiquidityETH}>
                      Supply
                    </SwapingButton>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* <SwapingButton disabled={getInputBTNDisabled ? true : false} p="10px 0" style={{ margin: "20px 0 0" }} onClick={handleAddLiquidityETH}>
                      Supply
                    </SwapingButton>
          <SwapingButton p="10px 0" style={{ margin: "20px 0 0" }} onClick={handleCreatePair}   >Create Pair</SwapingButton>
          <SwapingButton p="10px 0" style={{ margin: "20px 0 0" }} onClick={handleApprove}   >Approve</SwapingButton>
         
          <SwapingButton p="10px 0" style={{ margin: "20px 0 0" }} >Enter Amount</SwapingButton> */}


        </>
      )}
    </>
  );
};

export default AddLiuidity;
