import styled from "@emotion/styled";
import React from "react";

const ModalRecent1stbox = styled.div`
font-size: 12px;
font-weight: 500;
display: flex;
color: #ffffff;
justify-content: space-between;
`
const ModalBox = styled.div`
padding: 10px 0 0;
`
const Minumum = () => {
  return (
    <ModalBox>
      <ModalRecent1stbox>
        <span style={{ opacity: 0.6, color: "white" }}>Minimum Recieved</span>
        <span style={{ color: "white" }}> 24.2 Usdt</span>
      </ModalRecent1stbox>
      <ModalRecent1stbox>
        <span style={{ opacity: 0.6, color: "white" }}>Price Impact</span>
        <span style={{ color: "white" }}> 0.001%</span>
      </ModalRecent1stbox>
      <ModalRecent1stbox>
        <span style={{ opacity: 0.6, color: "white" }}>
          Liquidity Provider Fee
        </span>
        <span style={{ color: "white" }}> 0.01%</span>
      </ModalRecent1stbox>
    </ModalBox>
  );
};
export default Minumum;
