import React, { useState } from "react";
import ImportIcon from "../../../../assets/images/ImportIcon.png";
import { P, SectionSmallHeading } from "../../../Styles/style";
import {
  InpuFieldSelect,
  InputDIv,
  Inputoken,
  SelectedToken,
  Split,
  Swaper,
  SwapingBackButton,
  SwapingButton,
  Tokenimg,
  WrapDiv,
} from "../S1_Swap/style";
import { ColoredDiv, OutlinedBtn } from "../style";
import {
  FlokiTokenABI,
  RouterContractABI,
  RouterContractAddress,
  tokenABI,
  factoryContractAddress,
  factoryABI,
  PLSAddress,
  USDCAddress,
  DAIAddress,
  USDTAddress,
  contractAbi,
  FlokiTokenAddress,
} from "../../../../Abis&Ca";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import slipage from "../../../../assets/images/slipage.png";
import swapicon from "../../../../assets/images/swapicon.png";
import swapArrow from "../../../../assets/images/swapArrow.png";
import pls from "../../../../assets/images/pls.png";
import floki from "../../../../assets/images/floki.png";
import inc from "../../../../assets/images/inc.png";
import timer from "../../../../assets/images/que.png";
import dai from "../../../../assets/images/dai.png";
import pulsexicon from "../../../../assets/images/pulsexicon.png";
import usdc from "../../../../assets/images/usdc.png";
import usdt from "../../../../assets/images/usdt.png";
import { AiOutlineDown } from "react-icons/ai";
import Main from "./main";
const ImportPool = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [formattedFirstBalance, setFormattedFirstBalance] = useState("");
  const [formattedSecondBalance, setFormattedSecondBalance] = useState("");

  const [useInputSlipage, setInputSlipage] = useState("10.0");
  const [useInputDeadline, setInputDeadline] = useState("20");

  const [InputTokenAddress, setInputTokenAddress] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [pairAddress, setPairAddress] = useState(
    "0xceA2924b787Ec1200Dba80D1Dcb890B8124Bffd4"
  );
  const [ExistToken, setExistToken] = useState("");
  const [importedTokenAddress, setImportedTokenAddress] = useState("");
  const [OpenInfo, setOpenInfo] = useState(false);
  const [dialogOpen1, setDialogOpen1] = useState(false);
  const [dialogOpen2, setDialogOpen2] = useState(false);

  const [BottomAddress, setBottomAddress] = useState(FlokiTokenAddress);
  const [UpperAddress, setUpperAddress] = useState(PLSAddress);

  const [PageState , setPageState] = useState("import")

  const handleFindBtn = () => {
    setPageState("main")
  }
  const toggleScreen = () => setIsOpen(!isOpen);
  // for dialog
  const handleSetMaxSlipage = () => setInputSlipage("100");
  const handleAutoSlipage = () => setInputSlipage("20");
  const [selectedOption1, setSelectedOption1] = useState({
    title: "Cal",
    icon: pls,
    address: PLSAddress,
  });
  const [selectedOption2, setSelectedOption2] = useState({
    title: "Select A Token",
    icon: null,
    address: FlokiTokenAddress,
  });

  const [toggle, setToggle] = useState(true);
  const [options, setOptions] = useState(() => {
    // Retrieve options from localStorage, or use a default value if not found
    const storedOptions = localStorage.getItem("importedTokens");
    const parsedOptions = storedOptions
      ? JSON.parse(storedOptions)
      : [
          // {
          //   title: "FLOKI",
          //   icon: floki,
          //   address: "0x60C89f5eC551350235388b33d10643d46CaBE907",
          //   pairaddress: "0xBC639079Ec68c583294cecD52a9dCD1561B70340",
          //   disabled:
          //     selectedOption1.title === "FLOKI" ||
          //     selectedOption2.title === "FLOKI",
          // },
          // {
          //   title: "PLSX",
          //   icon: pulsexicon,
          //   pairaddress: "0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9",
          //   address: "0x95B303987A60C71504D99Aa1b13B4DA07b0790ab",
          //   disabled:
          //     selectedOption1.title === "PLSX" ||
          //     selectedOption2.title === "PLSX",
          // },
          // {
          //   title: "INC",
          //   icon: inc,
          //   pairaddress: "0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA",
          //   address: "0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d",
          //   disabled:
          //     selectedOption1.title === "INC" ||
          //     selectedOption2.title === "INC",
          // },
          // {
          //   title: "DAI",
          //   icon: dai,
          //   pairaddress: "0xE56043671df55dE5CDf8459710433C10324DE0aE",
          //   address: "0xefD766cCb38EaF1dfd701853BFCe31359239F305",
          //   disabled:
          //     selectedOption1.title === "DAI" ||
          //     selectedOption2.title === "DAI",
          // },
          // {
          //   title: "PLS",
          //   icon: pls,
          //   address: "0xA1077a294dDE1B09bB078844df40758a5D0f9a27",
          //   disabled:
          //     selectedOption1.title === "PLS" ||
          //     selectedOption2.title === "PLS",
          // },
          // {
          //   title: "USDC",
          //   icon: usdc,
          //   pairaddress: "0x6753560538ECa67617A9Ce605178F788bE7E524E",
          //   address: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
          //   disabled:
          //     selectedOption1.title === "USDC" ||
          //     selectedOption2.title === "USDC",
          // },
          // {
          //   title: "USDT",
          //   icon: usdt,
          //   address: "0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f",
          //   disabled:
          //     selectedOption1.title === "USDT" ||
          //     selectedOption2.title === "USDT",
          // },
          // {
          //   title: "FLUFFY",
          //   icon: "",
          //   pairaddress: "0xB8331Abf8aCf6e9D33C69e34fdA213B7b97e9Bf2",
          //   address: "0xA9D4230b4899e6aaC0D84e540941B3832ABA3ba0",
          //   disabled:
          //     selectedOption1.title === "FLUFFY" ||
          //     selectedOption2.title === "FLUFFY",
          // },
          // {
          //   title: "RICH",
          //   icon: "",
          //   pairaddress: "0xCEc92bb8B196d3D797bdBB60c1029825C0CD478a",
          //   address: "0x5a266ae80608148F2351bf29903bD39B9d0D5Ced",
          //   disabled:
          //     selectedOption1.title === "RICH" ||
          //     selectedOption2.title === "RICH",
          // },
        ];
    return parsedOptions.map((option) => ({
      ...option,
    }));
  });

  // console.log("options",options);
  const toggleChecked = () => {
    setToggle((toggle) => !toggle);
  };

  const activeValue = toggle ? "UPPERFIELD" : "BOTTOMFIELD";

  const handleFirstInputChange = (e) => {
    const value = e.target.value;
    setFormattedFirstBalance(value);
  };
  const handleSecondInputChange = (e) => {
    const value = e.target.value;
    setFormattedSecondBalance(value);
  };
  const handleDialogOpen1 = () => setDialogOpen1(true);
  const handleDialogOpen2 = () => setDialogOpen2(true);
  const handleOptionSelect2 = (option) => {
    setSelectedOption2(option);
    setSelectedOption1((prevOption) =>
      prevOption.title === option.title
        ? { title: "", icon: null, address: "" }
        : prevOption
    );
    setDialogOpen2(false);
    setBottomAddress(option.address);
    setPairAddress(option.pairaddress);
  };

  const handleOptionSelect1 = (option) => {
    setSelectedOption1(option);
    setSelectedOption2((prevOption) =>
      prevOption.title === option.title
        ? { title: "", icon: null, address: "" }
        : prevOption
    );
    setDialogOpen1(false);
    setUpperAddress(option.address);
    setPairAddress(option.pairaddress);
  };
  const handleDialogClose1 = () => setDialogOpen1(false);
  const handleDialogClose2 = () => setDialogOpen2(false);

  const firstSelectedTokenProps = {
    onClick: toggle ? handleDialogOpen1 : handleDialogOpen2,
    imgSrc: toggle ? selectedOption1.icon : selectedOption2.icon,
    tokenValue: toggle ? selectedOption1.title : selectedOption2.title,
  };
  const secondSelectedTokenProps = {
    onClick: toggle ? handleDialogOpen2 : handleDialogOpen1,
    imgSrc: toggle ? selectedOption2.icon : selectedOption1.icon,
    tokenValue: toggle ? selectedOption2.title : selectedOption1.title,
  };
  const firstInputProps = {
    type: "number",
    placeholder: "0.00",
    value: toggle ? formattedFirstBalance : formattedSecondBalance,
    onChange: toggle ? handleFirstInputChange : handleSecondInputChange,
  };

  const secondInputProps = {
    type: "number",
    placeholder: "0.00",
    value: toggle ? formattedSecondBalance : formattedFirstBalance,
    onChange: toggle ? handleSecondInputChange : handleFirstInputChange,
  };

  const handleSlipage = (event) => {
    const slipageValue = event.target.value;
    if (slipageValue <= 100) {
      setInputSlipage(slipageValue);
    } else {
      setInputSlipage("100");
    }
  };
  const handleDeadline = (event) => {
    const deadLine = event.target.value;
    setInputDeadline(deadLine);
  };
  const importToken = (address) => {
    const existingToken = options.find((option) => option.address === address);
    if (existingToken) {
      setExistToken(existingToken.title);
      setOpenInfo(true);
      console.log("Token already exists:", existingToken.title);
      return;
    }
    const newToken = {
      title: tokenSymbol,
      icon: null,
      pairaddress: pairAddress,
      address,
      disabled: false,
    };
    const updatedOptions = [...options, newToken];
    setOptions(updatedOptions);
    localStorage.setItem("importedTokens", JSON.stringify(updatedOptions));
    setBottomAddress(address);
    setTimeout(() => {
      setInputTokenAddress("");
      setTokenSymbol("");
      setImportedTokenAddress("");
    }, 5000);
  };
  return (
    <>
    {
        PageState === "main" ? <Main/> : 

    <>
      <Dialog
        PaperProps={{
          style: {
            minWidth: "400px",
            minHeight: "400px",
            background:
              "linear-gradient(0deg, rgb(0 0 0 / 89%) 40%, rgb(0, 0, 0) 78%)",
            border: "1px solid #ffffff2b",
          },
        }}
        open={dialogOpen1}
        onClose={handleDialogClose1}
      >
        <Split>
          <DialogContent style={{ color: "#fff" }}>Select Token</DialogContent>
          <DialogActions>
            <Button style={{ color: "#fff" }} onClick={handleDialogClose1}>
              X
            </Button>
          </DialogActions>
        </Split>
        <DialogActions>
          <Inputoken
            type="text"
            placeholder="Enter token address"
            value={InputTokenAddress}
            onChange={(e) => setInputTokenAddress(e.target.value)}
          />
        </DialogActions>
        <Split p="0 20px 0 0">
          {importedTokenAddress && (
            <>
              <P p="0 20px " m="0">
                Token : {tokenSymbol}
              </P>
              <SwapingButton
                p="7px 0"
                maxWidth="30%"
                onClick={() => importToken(importedTokenAddress)}
              >
                Import
              </SwapingButton>
            </>
          )}
        </Split>
        <DialogContent>
          {options.map((option) => (
            <WrapDiv>
              <Tokenimg
                // style={{ opacity: option.disabled ? 0.5 : 1 }}
                src={option.icon || timer}
                alt={option.title}
                className="dialog-icon"
              />
              <Button
                key={option.title}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  color: "#fff",
                  // opacity: option.disabled ? 0.5 : 1, // Apply opacity to disabled options
                }}
                onClick={() => handleOptionSelect1(option)}
                // disabled={option.disabled}
              >
                &nbsp;&nbsp;
                {option.title}
              </Button>
            </WrapDiv>
          ))}
        </DialogContent>
      </Dialog>

      <Dialog
        PaperProps={{
          style: {
            minWidth: "400px",
            minHeight: "400px",
            background:
              "linear-gradient(0deg, rgb(0 0 0 / 89%) 40%, rgb(0, 0, 0) 78%)",
            border: "1px solid #ffffff2b",
          },
        }}
        open={dialogOpen2}
        onClose={handleDialogClose2}
      >
        <Split>
          <DialogTitle style={{ color: "#fff" }}>Select Token</DialogTitle>
          <DialogActions>
            <Button style={{ color: "#fff" }} onClick={handleDialogClose2}>
              X
            </Button>
          </DialogActions>
        </Split>
        <DialogActions>
          <Inputoken
            type="text"
            placeholder="Enter token address"
            value={InputTokenAddress}
            onChange={(e) => setInputTokenAddress(e.target.value)}
          />
        </DialogActions>
        <Split p="0 20px 0 0">
          {importedTokenAddress && (
            <>
              <P p="0 20px " m="0">
                Token: {tokenSymbol}
              </P>
              <SwapingButton
                p="7px 0"
                maxWidth="30%"
                onClick={() => importToken(importedTokenAddress)}
              >
                Import
              </SwapingButton>
            </>
          )}
        </Split>
        <DialogContent>
          {options.map((option) => (
            <WrapDiv>
              <Tokenimg
                // style={{ opacity: option.disabled ? 0.5 : 1 }}
                src={option.icon || timer}
                alt={option.title}
                className="dialog-icon"
              />
              <Button
                key={option.title}
                style={{
                  fontSize: "18px",
                  display: "flex",
                  flexDirection: "column",
                  color: "#fff",
                  // opacity: option.disabled ? 0.5 : 1, // Apply opacity to disabled options
                }}
                onClick={() => handleOptionSelect2(option)}
                // disabled={option.disabled}
              >
                &nbsp;&nbsp;
                {option.title}
              </Button>
            </WrapDiv>
          ))}
        </DialogContent>
      </Dialog>
      <div style={{ textAlign: "center" }}>
        <img src={ImportIcon} />
        <SectionSmallHeading align="center" p="10px 0 20px"  style={{position:"relative"}}>
          Import Pool
          <SwapingBackButton p="0px 0px"  style={{ top:"27%"}} onClick={handleFindBtn}>{'< '} back</SwapingBackButton>
        </SectionSmallHeading>
      </div>
      <ColoredDiv p="6px 10px" {...firstSelectedTokenProps}>
        <div style={{display:"flex" , alignItems:"center"}}>
          <Tokenimg src={firstSelectedTokenProps.imgSrc || timer} />
          &nbsp;&nbsp;
          {firstSelectedTokenProps.tokenValue}
          </div>
          {/* &nbsp;&nbsp; */}
          <AiOutlineDown style={{ margin: "0 4px", color: "#fff" }} />
      </ColoredDiv>
      <Split>
      <P color="#ffffff" fs="14px" p="0 50%" m="10px" align="center">
          {" "}
          +{" "}
        </P>
      </Split>
      <ColoredDiv p="6px 10px" {...secondSelectedTokenProps}>
        <div style={{display:"flex" , alignItems:"center"}}>
          <Tokenimg src={secondSelectedTokenProps.imgSrc || timer} />
          &nbsp;&nbsp;
           {secondSelectedTokenProps.tokenValue}
          </div>
          {/* &nbsp;&nbsp; */}
          <AiOutlineDown style={{ margin: "0 4px", color: "#fff" }} />
      </ColoredDiv>
      <Swaper>
        <P color="#ffffff42" fs="14px" p="0" m="10px 0 20px" align="center">
          {" "}
          Select A Token To Find Your Liquidity.{" "}
        </P>
        
      </Swaper>
      </>
    }
    </>
  );
};

export default ImportPool;
