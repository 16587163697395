import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import MenuList from '@mui/material/MenuList';
import { useNetwork, useSwitchNetwork, useBalance } from "wagmi"
import styled from '@emotion/styled';
import { useAccount } from "wagmi"
import NetworkSwitch from '../../multiNetworkButtons/MenuWalletButton/NetworkSwitch';

const ButtonGroupMain = styled(ButtonGroup)`
box-shadow: none !important;
`
const SwitchBtn = styled(Button)`
    background-color: ${(props) => props.theme.btnPrimary};
    color: #ffffff;
    outline: unset !important;
    text-transform: capitalize;
    font-weight: 600;
    border-color: #242424 !important;

    &.right,&.left{border-radius: 20px}
    &.right{min-width: 90px;padding: 7px 15px 7px 15px}
    &.left{padding-left: 0px}
    &.wrongNt{border-radius: 20px; padding: 8px 30px !important;}
    svg{fill: #ffffff;}
    &:hover{
      background-color: ${(props) => props.theme.btnPrimary};
        color: #ffffff;
        svg{fill: #ffffff;}
    }
`

const NetworkIcon = styled.img`
    height: 20px;
    width: 20px;
    padding: 1px;
    margin-right: 7px;
    background: #ffffff;
    border-radius: 50%;
    object-fit: contain;
`

const Div = styled.div`
  display: inline-block;
  margin: 10px 10px 10px 0;
  @media screen and (max-width: 420px) {
    display: block;
    margin: 10px 0 10px 0;
  }
`;

const Network = styled.div`
  font-size: 14px;
  border-radius: 20px;
  display: flex;
  background-color: ${(props) => props.theme.bgTertiary};
  color: ${(props) => props.theme.textPrimary};
  align-items: center;
  display: inline-flex;
`;
const BalanceShow = styled.span`
color: ${(props) => props.theme.textPrimary};
padding: 0 10px 0 15px; 
min-width: 50px; 
text-align: center;
`
export default function NetworkBalance() {
  const anchorRef = useRef(null);
  const [getBalance, setBalance] = useState(0);
  const [getNetwork, setNetwork] = useState('');
  const [getIcon, setIcon] = useState('');
  const [getChainId, setChainID] = useState(0);
  const userDetails = useAccount()
  const { chain } = useNetwork()
  const userBalance = useBalance({
    address: userDetails?.address,
    chainId: chain?.id,
  })
  
  useEffect(()=>{
    setBalance(() => {
      let bal = (userBalance?.data?.formatted)
      return Math.round(bal * 100) / 100
    })
    setIcon(chain?.icon)
    setNetwork(chain?.nativeCurrency?.symbol)
    console.log('test',chain)
    setChainID(chain?.id)
  },[userBalance,getBalance,getNetwork,getChainId, chain])

  return (
    <Div>
      {getNetwork?
        <>
            { getChainId !== 8991 ?
                <>
                <NetworkSwitch />
                </>
                :
                <Network>
                    <BalanceShow>
                        {getBalance}
                    </BalanceShow>
                    {/* <ButtonGroupMain variant="contained" ref={anchorRef} aria-label="Switch Netwrok">
                        <SwitchBtn className="right">
                          <NetworkIcon src={getIcon} />{getNetwork}
                        </SwitchBtn>
                    </ButtonGroupMain> */}
                </Network>
            }
        </>
        :
        null
      }
    </Div>
  );
}