import styled from "@emotion/styled";
import { Container, Grid, Typography } from "@mui/material";

const SectionWraper = styled(Container)`
  padding: 0 !important;
  width: 100%;
  height: 100vh;
  z-index: 0;
  position: relative;
  background-image: url("./Image/tokenomicsBg.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 599px) {
    /* height: auto; */
  }
`;
const GradiantDiv = styled.div`
  padding: 0 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left:0;
  z-index: 1;
  background-image: radial-gradient(circle at 110% 50%, #0000, #091C23 60%);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;
const ContainerWraper = styled(Container)`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
`;
const EmptyGrid = styled(Grid)`
  width: 100%;
  height: 80px;
  @media (max-width: 599px) {
    display: none;
  }
`;
const Paragraph = styled(Typography)`
  color: #fff;
  font-size: 14px;
  font-family: ${props => props.fm ? props.fm : "PoppinsR"};
  margin-top: ${props => props.mt ? props.mt : "20px"};
  z-index: 1;
  text-align: ${props => props.ta};
`;
const Image = styled.img`
  width: 100%;
  max-width: 280px;
  height: auto;
  z-index: 1;
  @media (max-width: 599px) {
    max-width: 200px;
  }
`;
const RoadMapImage = styled.img`
  position: absolute;
    max-width: 50px;
    padding: 10px;
    
    border: 1px solid ##ffffff26;
    border-radius: 30px;
    background: #1F3037;
    top: -10%;
    left: 42%;
`;
const ImageGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: 599px) {
    justify-content: center;
  }
`;
const ParentGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  z-index: 1;
  @media (max-width: 599px) {
    flex-direction: column-reverse;
  }
`;
const ContentGrid = styled(Grid)`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* margin: 50px 0 0; */
  @media (max-width: 599px) {
    margin: 10px 0 0;
  }
`;
const TokenDiv = styled.div`
    width: 90%;
    height: auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
`;
const InnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const FooterDiv = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
  @media(max-width :599px){
        flex-direction: column;
    }
`;

const SocialDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: PoppinsR;
  margin: 10px 0 0;
  z-index: 1;
  /* @media(max-width :599px){
        flex-direction: column;
    } */
`;
const Div = styled.div`
  width: 100%;
  margin: 10px 10px 0;
  padding: 5px 10px;
  border: ${props => props.b ? props.b : "1px solid #fff"};
  border-radius: 20px;  
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${props => props.bgc};
`
const GRID = styled(Grid)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export {
  SectionWraper,
  Paragraph,
  EmptyGrid,
  Image,
  ImageGrid,
  ParentGrid,
  ContentGrid,
  TokenDiv,
  FooterDiv,
  SocialDiv,
  GradiantDiv,
  ContainerWraper,
  RoadMapImage,
  InnerDiv,
  Div,
  GRID

};
