import styled from "@emotion/styled"
import React, { useEffect, useState } from "react"
import { useNetwork } from "wagmi"
import ConnectWallet from "./MenuWalletButton/ConnectWallet"
import NetworkSwitch from "./MenuWalletButton/NetworkSwitch"

const ConnectBTN = styled.div`
    background-color: #242424;
    display: flex;
    width: max-content;
    border-radius: 10px;
    font-family: "Poppins-Regular";
    button{
        background-color: unset;
        border-radius: 10px;
        border: unset;
        outline: unset;
        font-size: 16px;
        font-weight: 700;
        padding: 10px;
        margin: auto;
        width: 154px;
        /* height: 48px; */

        &.add{border-radius: 10px 0 0 10px; padding: 8px 10px 8px 20px; width: unset;}
        &.dd{border-radius: 0 10px 10px 0; padding: 0px 15px 0px 10px; width: unset;}
        
        &:hover, &:active, &:focus {
            background: #00000021;
        }
        svg {
            fill: ${(props) => props.theme.textPrimary}; 
        }
    }
    &:hover, &:active, &:focus {
        background-color: ${(props) => props.theme.btnPrimaryHover};
    }
    
`
const MenuConnetButton = styled(ConnectBTN)`
    width: 100%;
    max-width: 200px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    margin-left: auto;

    button{
        width: 100%;
        height: 44px;
        cursor: pointer;
        &:disabled,
        &[disabled]{
        border: 1px solid ${props => props.theme.btnborderDisable};
        background-color: ${props => props.theme.btnDisable};
        color: ${props => props.theme.btnTxtDisable};
        }
    }
`
const WalletButton = (props) => {
    const [chainId, setChainId] = useState('0');
    const { chain } = useNetwork();

    useEffect(() => {
        if (chain) { setChainId(chain.id) }
    }, [chainId, chain])
    if (chainId != '5' 
    // ||  chainId != '8991'
    ) {
        console.log('chainId',chainId)
        // return <ConnectWallet />
    } else {
        // console.log('chainId',chainId)
        return <ConnectWallet />

    }

}

export default WalletButton