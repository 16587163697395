import React, { useState } from "react";
import pls from "../../../../assets/images/pls.png";
import que from "../../../../assets/images/que.png";
import { P, SectionSmallHeading } from "../../../Styles/style";
import {
  Split,
  Swaper,
  SwapingBackButton,
  SwapingButton,
  Tokenimg,
} from "../S1_Swap/style";

import Main from "./main";
import { Slider } from "@mui/material";
import AddLiuidity from "./addLiquidity";

const marks = [
    {
      value: 25,
      label: '25%',
    },
    {
      value: 50,
      label: '50%',
    },
    {
      value: 75,
      label: '75%',
    },
    {
      value: 100,
      label: '100%',
    },
  ];
  
  
  const AddAmount = () => {
      const [PageState, setPageState] = useState("AddAmount");
      const [progressValue , setProgressValue]=  useState("25%")
      const handleBackBtn = () => {
          setPageState("main");
        };
        const handleAmuntBtn = () => {
            setPageState("AddLiquidity");
        };
        function valuetext(value) {
          setProgressValue(`${value}%`)
          return `${value}`;
        }
  return (
    <>
    {
        PageState === "main" ? <Main/> : 
        PageState === "AddLiquidity" ? <AddLiuidity amountAdded={progressValue}/> : 

    <>
      <div style={{ textAlign: "center" }}>
        <SectionSmallHeading fs="16px"  p="10px 0 10px" style={{position:"relative"}}>
        Remove PLS-ELON INU Liquidity
          <SwapingBackButton onClick={handleBackBtn}>{'< '} back</SwapingBackButton>
        </SectionSmallHeading>
      </div>
      <Swaper p="0 20px">
        <Swaper p="10px 10px 10px 0">
      <SectionSmallHeading fs="14px"  p="5px 0 ">
      Amount
        </SectionSmallHeading>
        <SectionSmallHeading fs="22px"  p="5px 0 ">
        {progressValue}
        </SectionSmallHeading>
        <Slider
        aria-label="Custom marks"
        defaultValue={25}
        getAriaValueText={valuetext}
        step={1}
        valueLabelDisplay="auto"
        marks={marks}
      />
      </Swaper>
      </Swaper>
      <SectionSmallHeading fs="16px"  p="15px 0 10px">
      You Will Receive
        </SectionSmallHeading>
      <Swaper p="10px 20px">
      <Split p="5px 0">
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Tokenimg src={pls} />
              <P fs="14px" p="0px 0 0 10px" m="0" align="center"> Pooled Cal:</P>
            </div>
            <P color="#ffffff42" fs="14px" p="5px 0 0 10px" m="0" align="center">0.00774121</P>
          </Split>
          <Split  p="5px 0">
            <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
              <Tokenimg src={que} />
              <P fs="14px" p="0px 0 0 10px" m="0" align="center">Pooled Calc:</P>
            </div>
            <P color="#ffffff42" fs="14px" p="5px 0 0 10px" m="0" align="center">10000</P>
          </Split>
      </Swaper>
      <Split p="20px 0 0">
                <SwapingButton  maxWidth="49%" onClick={handleAmuntBtn}>Enable</SwapingButton>
                <SwapingButton bgc="#ffffff42"  maxWidth="49%" >Remove</SwapingButton>
          </Split>
      </>
    }
    </>
  );
};

export default AddAmount;