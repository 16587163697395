import React, { forwardRef, useEffect, useState } from "react";
import Container from "@mui/material/Container";
import Web3 from "web3";
// import logo from "./image/Logo.png";
import wallet from "../../../../../assets/images/wallet.png";
import styled from "@emotion/styled";
import Nav from "../../../../Components/DashboardMenuSingleNetwork"
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { P } from "../../../../Styles/style";
import CustomButton from "../../../../Components/CustomBtn";
import { MenuLink } from "../../../../Components/Menu/styles";
const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`;

const ConnectButt = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
`;
const Navbar = ({ onBalanceUpdate }) => {
  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [getAddress, setAddress] = useState("connecting");
  const [chainId, setChainId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [getUserBalance, setUserBalance] = useState("");

  const [error, setError] = useState("");
  const [OpenError, setOpenError] = useState(false);
  const [Open, setOpen] = useState(false);
  
  return (
    <div>
     
      <Container maxWidth="xl">
        <Wrapper>
        </Wrapper>
      </Container>
    </div>
  );
};
export default Navbar;
