import styled from "@emotion/styled";
import { Accordion, Button, Grid, Tab, Tabs } from "@mui/material";

const GridWraper = styled(Grid)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 10px 0 30px;
`;
const TabWrap = styled(Tab)`
  color: rgba(255, 255, 255, 0.7);
  & .Mui-selected {
    color: "#1890ff";
  }
  & .Mui-focusVisible {
    background-color: "#d1eaff";
  }
`;
const OutlinedBtn = styled(Button)`
    background-color: transparent;
    color:#fff;
    border: 1px solid;
    border-radius: 10px;
    margin: 10px 0;
    font-family: P-B;
    width:100%;
    padding: ${(props) => (props.p ? props.p : "9px 0")};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
    :hover{
        border-color: #fff;
    }
    @media (max-width :1170px){
      max-width: ${props => props.maxWidth1170 ? props.maxWidth1170 : props.maxWidth};
    }
    @media (max-width :400px){
      max-width: ${props => props.maxWidth400 ? props.maxWidth400 : props.maxWidth};
    }

`
const ColoredDiv = styled.a`
position: absolute;
justify-content: space-between;
  top: 7px;
  margin: 10px 0 30px;
  color:#fff;
  display:flex;
    border-radius: 10px;
  align-items:center;
  background:##ffffff26;
 text-align: center;
  cursor: pointer;
  position: relative;
  border:none;
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  font-size: 16px;
  width:100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  font-family: "P-B";
`
const AccordionStyled = styled(Accordion)`
  background-color:#ffffff263d !important;
  border-radius: 8px !important;
  margin: 20px 0;
  text-align: center;

`
export { GridWraper, TabWrap ,OutlinedBtn,ColoredDiv,AccordionStyled};
