import styled from "@emotion/styled"
import { Grid } from "@mui/material";

import swapbg from "../../../../assets/images/swapbg.png";
const WrapDiv = styled.div`
display:flex;
align-items: center;
`
const InputDIv = styled.div`
  position: relative;
  width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

`
const InpuFieldSelect = styled.input`
 width: 100%;
  border-radius: 6px;
  border: none;
  /* padding: 8px 0px 0px 0px;   */
  background-color:transparent !important;
  text-align: end;
  color: #ffffff;
  font-size: 20px;
  max-width: -webkit-fill-available;
  max-width: -moz-available;

:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}
`
const SlipageControl = styled.input`
 border-radius: 6px;
  background-color: #ffffff263d;
  border: none;
  color: white;
  text-align: end;
  padding: 3px 20px;
   margin-left: ${(props) => (props.ml ? props.ml : "10px")};
  width: 100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "90%")};

.Ray---Connect---Modal---Text:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}
`

const Wrapper = styled.div`
background-image: url("./Image/heroBg.png");
background-size: cover;
background-position: center;
position: relative;
min-height:100vh;
::after{
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  // position: absolute;
  top: 0;
  left: 0;
  scale: 1 1;
  background: #091c23a6;
  z-index: 0;
}
`
const SwapDiv = styled.div`
padding:20px 20px 20px;
position: relative;
      background: #ffffff26;
    backdrop-filter: blur(10px);
  border:1px solid #ffffff26;
  border-radius: 20px;
  z-index: 1;
  
`
const InsideWrap = styled.div`
display: flex;
align-items: center;
justify-content: center;
min-height: calc(100vh - 178px);
`
const Split = styled.div`
display: flex; justify-content: space-between; align-items: center;
padding: ${(props) => (props.p ? props.p : "3px 0")};
`
const MaxButton = styled.button`
padding:4px 10px;
border: none;
border-radius: 8px;
font-size: 18px;
color:black;
cursor: pointer;

background:#ffffff;
`
const Swaper = styled.div`
  padding:${props => props.p ? props.p : "20px 20px"};
  background: #161616;
  border:1px solid #ffffff263d;
  border-radius: 8px;
  margin: 3px 0;
  text-align: center;
`
const SelectedToken = styled.a`
 /* position: absolute; */
  /* top: 7px; */
  /* margin: auto; */
  cursor: pointer;
  color:#fff;
  display:flex;
  /* background-color: ${props => props.bgc ? props.bgc : "#484343f5"}; */
  padding: 6px 0 6px 0px;
  border-radius: 10px;
  align-items:center;
`
const Tokenimg = styled.img`
width: 100%;
height:auto;
max-width: 30px;
`

const SwapButton = styled.button`
background:black;
 text-align: center;
  cursor: pointer;
  border:none;
  padding: 8px 8px 0px;
  border-radius: 20px;
  position: absolute;
  bottom:-25px;
`
const SwapingButton = styled.button`
  background: black;
  text-align: center;
  cursor: pointer;
  border:none;
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  font-size: 16px;
  border-radius: 10px;
  color: #fff;
  width:100%;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  font-family: "P-B";
  &:disabled,
  &[disabled]{
    border: 1px solid #999999;
    background:gray;
    cursor: not-allowed;
    color: #fff;
  }
`
const SwapingBackButton = styled.button`
  background: #ffffff;
  text-align: center;
  cursor: pointer;
  border:none;
  padding: 0 5px;
  font-size: 14px;
  border-radius: 8px;
  color: black;
  font-family: "P-R";
  position:absolute;
  right:0%;
`
const SlipageBox = styled.div`
background:linear-gradient(60deg,#410EB3 40%,#B8008D 78%);
 text-align: center;
  cursor: pointer;
  border:none;
  display:flex;
  align-items:center;
  padding: 5px 6px;
  margin: 15px 0px;
  border-radius: 10px;
`

const PopupWrap = styled.div`
position:relative;
`;

const Popup = styled.div`
  position: fixed;
  bottom: 4%;
  left: 20%;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: end;
  @media screen and (max-width: 499px) {
    left: 5%;
  }
`;

const PopupContent = styled.div`
background:linear-gradient(90deg,#000000d6 40%,#000000 78%);

border:1px solid #ffffff22;
  padding: 5px 10px;
  border-radius: 5px;
`;
const CloseButton = styled.button`

float: right;
  color: white;
  border: none;
  background-color: transparent;
  font-size: 20px;
  font-weight: 600;
   cursor: pointer;
`
const SettingSlip = styled.img`
  width:100%;
  height:auto;
  max-width: 20px;

`
const DIvWrapper = styled.div`
     height: 32px;
    border-radius: 10px;
    padding: 0px 0px 0 11px;
    background-color: #ffffff263d;
    display: flex;
    align-items: center;
    margin: 10px 0;
`
const Inputoken = styled.input`
width:100%;
background-color: #ffffff2b;
color:#fff;
border:none;
border-radius: 10px;
padding:10px 15px;
margin:0px 10px;
:focus-visible{
  outline:none;
}
`
const GridWrap = styled(Grid)`
display:flex;
align-items:center;
`
const ExhangeInnerDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`
export {GridWrap,Inputoken,WrapDiv,SettingSlip,SwapingBackButton,ExhangeInnerDiv, DIvWrapper,InputDIv,InpuFieldSelect,SlipageControl,Wrapper, SwapDiv, InsideWrap, Split, MaxButton, Swaper, SelectedToken,Tokenimg,SwapButton,SwapingButton,SlipageBox,PopupWrap,Popup,PopupContent,CloseButton,}